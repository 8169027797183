import React from "react";
import "./GridView.css";
import "./GridViewMobile.scss";
import DevelopmentSmall from "./DevelopmentSmall/DevelopmentSmall";
import houseIcon from "./DevelopmentSmall/house.svg";

function GridView(props) {
  //   const openLink = (url) => {
  //     window.open(url, "_blank", "noopener,noreferrer");
  //   };
  return (
    <>
      <div className="grid-container" obj={props.data[0]}>
        {props.data.map((obj) => {
          return (
            <DevelopmentSmall
              key={obj["unique-id"]}
              obj={obj}
              // onClick={props.onClick}
              // onClick={() => {
              //   console.log("clicked");
              //   openLink(obj["weixin"]);
              // }}
              showFav={props.showFav}
              renderWithSort={props.renderWithSort}
              sort={props.sort}
            />
          );
        })}
      </div>
      <p className="including-houses" style={{ marginLeft: "60px", marginBottom: "40px", fontSize: "20px", display: "flex", alignItems: "center" }}><img className="house-icon bottom" src={houseIcon} />&nbsp; Including houses</p>
    </>
  );
}

export default GridView;
