import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import jwtDecode from "jwt-decode";

const initialState = {
    accessToken: "",
    status: "idle",
    error: null
};

export const asyncFetchAccessToken = createAsyncThunk("accessToken/fetchAccessToken", async (token) => {
    const url = process.env.NODE_ENV === "production" ? "api/v1/refresh_token" : 'http://localhost:4000/api/v1/refresh_token';
    let getNewAccessToken = false;
    if (token) {
        try {
            const { exp } = jwtDecode(token);
            if (Date.now() >= exp * 1000 - 50) { // pass the time
                getNewAccessToken = true;
            } else {
                getNewAccessToken = false;
            }
        } catch (err) {
            console.log(err);
        }
    }

    if (!token || getNewAccessToken) {
        const response = await axios({
            method: 'post',
            url: url,
            withCredentials: true,
        });

        if (!response.data) throw new Error("response problem");
        return response.data["accessToken"];
    }
});


// SLICE OBJECT
export const accessTokenSlice = createSlice({
    name: "accessToken",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchAccessToken.pending, (state) => {
                state.status = "loading";
            })
            .addCase(asyncFetchAccessToken.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.accessToken = action.payload;
            })
            .addCase(asyncFetchAccessToken.rejected, (state) => {
                state.status = "failed";
            });
    }
});


// SELECTORS
export const selectAccessToken = (state) => state.accessToken.accessToken;



// EXPORTS
export const { setAccessToken } = accessTokenSlice.actions;

export default accessTokenSlice.reducer;
