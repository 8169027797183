import React, { useState, useEffect } from 'react';
import "./Certificates.css";
import "./CertificatesMobile.css";
import Frame from '../Frame/Frame';
const imgArla = require("../../.././assets/media/certificates/arla.png");
const imgEmployerLiability = require("../../.././assets/media/certificates/employer-liability.png");
const imgIncorporation = require("../../.././assets/media/certificates/incorporation.png");
const imgInsurance = require("../../.././assets/media/certificates/insurance.png");
const imgNrla = require("../../.././assets/media/certificates/nrla.png");
const imgPcmp = require("../../.././assets/media/certificates/pcmp.png");
const imgPrs = require("../../.././assets/media/certificates/prs.png");

export default function Certificates() {
    let certificateImgs = {
        "certificate-incorporation": imgIncorporation,
        "certificate-prs": imgPrs,
        "certificate-arla": imgArla,
        "certificate-nrla": imgNrla,
        "certificate-pcmp": imgPcmp,
        "certificate-employer-insurance": imgEmployerLiability,
        "certificate-insurance": imgInsurance,
    };

    const [curCertificateImg, setCurCertificateImg] = useState(certificateImgs["certificate-incorporation"]);
    const [oldIndex, setOldIndex] = useState(0);

    const changeImg = () => {
        let ul = document.querySelector(".description-container");
        ul.style.overflow = "scroll";
        let ulHeight = ul.clientHeight;
        let y = ul.children[0].getBoundingClientRect().y;
        let index = Math.round(-y / ulHeight);
        if (index !== oldIndex) {
            setOldIndex(index);
            ul.children[index] && setCurCertificateImg(certificateImgs[ul.children[index].id]);
        }
    };

    useEffect(() => {
        function handleScroll() {
            let certificateHeading = document.querySelector(".certificate-heading");
            let ul = document.querySelector(".description-container");
            if (certificateHeading.getBoundingClientRect().top <= 0) {
                ul.style.overflowY = "scroll";
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className='certificate-heading'>
                <h2 language="en" className='about-subtitle'>Certificates</h2>
                <h2 language="zh" className='about-subtitle'>资质</h2>
            </div>
            <div className='certificates-container'>

                <ul className='description-container' onScroll={changeImg}>
                    <li id="certificate-incorporation">
                        <div language="en" className='certificate-content'>
                            <h3 className='certificate-title'>Certificate of Incorporation</h3>
                            <p className='certificate-description'>NVRE incorporated under the Companies Act 2006 as a private company, that the company is limited by shares, and the situation of its registered office is in England and Wales.</p>
                        </div>
                        <div language="zh" className='certificate-content'>
                            <h3 className='certificate-title'>公司注册证书</h3>
                            <p className='certificate-description'>NVRE 根据 2006 年公司法注册成立为私人公司，该公司为股份有限公司，其注册办事处位于英格兰和威尔士。</p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-incorporation"]} />
                        </div>
                    </li>
                    <li id="certificate-arla">
                        <div language="en" className='certificate-content'>
                            <h3 className='certificate-title'>ARLA Propertymark</h3>
                            <p className='certificate-description'>ARLA Propertymark membership ensures maximum protection for clients; offering Client Money Protection, Professional Indemnity insurance, membership of an independent redress scheme and being subject to Propertymark Conduct and Membership Rules and Disciplinary Procedures.</p>
                        </div>
                        <div language="zh" className='certificate-content'>
                            <h3 className='certificate-title'>ARLA Propertymark</h3>
                            <p className='certificate-description'>ARLA Propertymark 确保为客户提供最大程度的保护；提供客户资金保护、专业赔偿保险、Property Redress Scheme 的会员资格，并遵守 Propertymark 行为和会员准则以及纪律程序。</p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-arla"]} />
                        </div>
                    </li>
                    <li id="certificate-prs">
                        <div language="en" className='certificate-content'>
                            <h3 className='certificate-title'>Property Redress Scheme</h3>
                            <p className='certificate-description'>The main purpose of the redress scheme is to resolve or settle unresolved complaints from consumers who have suffered a loss as a result of the actions of the member.</p>
                        </div>
                        <div language="zh" className='certificate-content'>
                            <h3 className='certificate-title'>Property Redress Scheme</h3>
                            <p className='certificate-description'>Property Redress Scheme 的主要目的是解决消费者因成员行为而造成损失的纠纷。</p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-prs"]} />
                        </div>
                    </li>
                    <li id="certificate-nrla">
                        <div language="en" className='certificate-content'>
                            <h3 className='certificate-title'>NRLA</h3>
                            <p className='certificate-description'>The National Residential Landlords Association is the UK's largest membership organisation for private residential landlords, supporting and representing over 100,000 members.</p>
                        </div>
                        <div language="zh" className='certificate-content'>
                            <h3 className='certificate-title'>NRLA</h3>
                            <p className='certificate-description'>The National Residential Landlords 协会是英国最大的私人住宅房东会员组织，支持并代表超过 100,000 名会员。</p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-nrla"]} />
                        </div>
                    </li>
                    <li id="certificate-pcmp">
                        <div language="en" className='certificate-content'>
                            <h3 className='certificate-title'>Propertymark Client Money Protection</h3>
                            <p className='certificate-description'>Landlords and clients are well protected under the Propertymark Client Money Protection Scheme.</p>
                        </div>
                        <div language="zh" className='certificate-content'>
                            <h3 className='certificate-title'>Propertymark Client Money Protection</h3>
                            <p className='certificate-description'>Propertymark Client Money Protection 保护计划为房东和客户提供保护。</p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-pcmp"]} />
                        </div>
                    </li>
                    {/* <li id="certificate-employer-insurance">
                        <div className='certificate-content'>
                            <h3 className='certificate-title'>Certificate of Employers' Liability Insurance</h3>
                            <p className='certificate-description'></p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-employer-insurance"]} />
                        </div>
                    </li>
                    <li id="certificate-insurance">
                        <div className='certificate-content'>
                            <h3 className='certificate-title'>Certificate of Insurance</h3>
                            <p className='certificate-description'></p>
                        </div>
                        <div className='mobile-certificate-img'>
                            <Frame src={certificateImgs["certificate-insurance"]} />
                        </div>
                    </li> */}
                </ul>
                <div className='certificate-img-container'>
                    <Frame src={curCertificateImg} />
                </div>
            </div>
        </>
    );
}