import React, { useEffect, useState, useContext } from "react";
import "./Contact.scss";
import "./ContactMobile.scss";
import { sendEmail } from "../../api/email";
import { SalesContext } from "../../context/SalesContext";

function Contact() {
    const { lang } = useContext(SalesContext);
    const [name, setName] = useState('');
    const [to, setTo] = useState();
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const [response, setResponse] = useState('');

    useEffect(() => {
        setSubject(lang === "english" ? "Buying and Selling" : "买房卖房");
    });

    const submitForm = async (e) => {
        e.preventDefault();
        setDisableButton(true);
        const response = await sendEmail(name, to, subject, content);
        console.log(response);
        if (response) {
            setResponse("Email sent successfully");
            setTo("");
            setName("");
            setSubject(lang === "english" ? "Buying and Selling" : "买房卖房");
            setContent("");
        } else {
            setResponse("Email failed");
        }
        setDisableButton(false);
    };

    return (
        <div className="about-contact-us" id="contact-us">
            <div className="contact-title title">
                <h2 className="title-main" language="en">Contact Us</h2>
                <h2 className="title-main" language="zh">联系我们</h2>
                <p className="title-sub" language="en">Chat with us</p>
                <p className="title-sub" language="zh">为您解答疑虑</p>
                <div className="deco-dash"></div>
            </div>
            <form className="about-contact-us-form" onSubmit={submitForm}>
                <div className="form-item">
                    <label>
                        <span language="en">Full Name</span>
                        <span language="zh">姓名</span>
                        <input language="en" className="txt"
                            type="text"
                            placeholder="Your name"
                            value={name}
                            onChange={(e) => { setName(e.target.value); }} />
                        <input language="zh" className="txt"
                            type="text"
                            placeholder="您的姓名"
                            value={name}
                            onChange={(e) => { setName(e.target.value); }} />
                    </label>
                </div>
                <div className="form-item">
                    <label>
                        <span language="en">Email</span>
                        <span language="zh">邮箱</span>
                        <input className="txt"
                            type="email"
                            placeholder="your@email.com"
                            value={to}
                            onChange={(e) => { setTo(e.target.value); }} />
                    </label>
                </div>
                <div className="form-item">
                    <label language="en">
                        <span>Subject</span>
                        <select className="txt" value={subject} onChange={(e) => { setSubject(e.target.value); }}>
                            <option>Buying and Selling</option>
                            <option>Renting</option>
                            <option>General Enquiries</option>
                        </select>
                    </label>
                    <label language="zh">
                        <span>主题</span>
                        <select className="txt" value={subject} onChange={(e) => { setSubject(e.target.value); }} >
                            <option>买房卖房</option>
                            <option>租赁</option>
                            <option>其他</option>
                        </select>
                    </label>
                </div>
                <div className="form-item">
                    <label>
                        <span language="en">Message</span>
                        <span language="zh">留言</span>
                        <textarea className="txt" placeholder="" value={content} onChange={(e) => { setContent(e.target.value); }}></textarea>
                    </label>
                </div>
                <div className="form-item button">
                    <button language="en" type="submit" disabled={disableButton}>SUBMIT</button>
                    <button language="zh" type="submit" disabled={disableButton}>提交</button>
                </div>
                <p>{response}</p>
            </form>
        </div>
    );
}

export default Contact;