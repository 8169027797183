import React from "react";
import "./Footer.scss";
import "./FooterMobile.scss";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const qrFollowUs = require("../../assets/media/logo/qrCode/nvre-red-QR.png");
const qrChatToUs = require("../../assets/media/logo/qrCode/nvre-wechat-qrcode.png");
const arlaLogo = require("../../assets/media/logo/certificate/arla-logo.png");
const prsLogo = require("../../assets/media/logo/certificate/prs-logo.png");
const dpsLogo = require("../../assets/media/logo/certificate/dps-logo.png");
const nvreLogoPurple = require("../../assets/media/logo/company/nvreLogoPurple.png");

function Footer(props) {
    return (
        <div className={`${props.showFooter ? "footer" : "footer hide"} flex flex-col`}>
            <img className="footer-nvre-logo" src={nvreLogoPurple} alt="nvre-logo" />
            <address className="flex justify-between w-full">
                <div className="address">
                    <p className="footer-title"><span language="en">OFFICE</span><span language="zh">办公室</span></p>
                    <p className="footer-title-content">16 Berkeley Street, Mayfair <br />
                        London<br />
                        W1J 8DZ<br />
                        United Kingdom</p>
                    {/* <div className="footer-title-content">
                        <span language="en">EMAIL: </span>
                        <span language="zh">邮箱：</span>
                        <a href="mailto:info.estate@newvgroup.com">info.estate@newvgroup.com</a>
                    </div>
                    <div className="footer-title-content">
                        <span language="en">OFFICE: </span>
                        <span language="zh">办公室：</span>
                        <span>
                            +44 (0)20 3907 4024
                        </span>
                    </div> */}
                </div>
                <div className="contact-us">
                    <p className="footer-title"><span language="en">CONTACT US</span><span language="zh">联系我们</span></p>
                    <div className="footer-title-content">
                        <span language="en">EMAIL:</span>
                        <span language="zh">邮箱：</span>
                        <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>
                    </div>
                    <div className="footer-title-content">
                        <span language="en">OFFICE:</span>
                        <span language="zh">办公室：</span>
                        <span>
                            +44 (0)20 3907 4024
                        </span>
                    </div>
                    <div className="footer-title-content">
                        <span language="en">FORM:</span>
                        <span language="zh">咨询表格：</span>
                        <span language="en">Please click <Link to="/" hash="#contact-us" state={{ scrollToContact: true }} style={{ textDecoration: "underline" }}>here</Link> to fill out a contact form.</span>
                        <span language="zh">请点击<Link to="/" hash="#contact-us" state={{ scrollToContact: true }} style={{ textDecoration: "underline" }}>这里</Link>填写联系表格。</span>
                        {/* <span>Please click <NavLink to="/#contact-us" style={{ textDecoration: "underline" }}>here</NavLink> to fill out the form</span> */}
                    </div>
                </div>
                {/* <div className="links">
                    <p className="title"><span language="en">LINKS</span><span language="zh">链接</span></p>
                    <a href="http://www.newvgroup.com" target="_blank">New Vision International Group</a>
                    <a href="http://www.newvfo.com" target="_blank">New Vision Family Office</a>
                </div> */}
                <div className="qr-codes">
                    <div>
                        <p language="en">Chat To Us <br />On WeChat</p>
                        <p language="zh">微信<br />联系我们</p>
                        <img src={qrChatToUs} />
                    </div>
                    <div>

                        <p language="en">Follow Us</p>
                        <p language="zh">关注我们</p>
                        <img src={qrFollowUs} />
                    </div>
                </div>

            </address>
            <div className="divider"></div>
            <div className="copyright-container">
                <div>

                    <p>
                        <span language="en">&#169; Copyright 2023 NVRE</span>
                        <span language="zh">&#169; 2023 NVRE版权所有</span>
                    </p>
                    <p language="en">NVRE is a business name used by New Vision Real Estate Limited, registered in England and Wales. Company No.: 12987204. VAT Number: 386337268.</p>
                    <p language="zh">NVRE是由注册在英格兰和威尔士的 New Vision Real Estate Limited 所使用的商业名称。公司注册号：12987204。增值税号：386337268。</p>
                </div>
                <div>
                    <p>
                        <span language="en">NVRE IS A MEMBER OF</span>
                        <span language="zh">NVRE为成员于</span>
                    </p>
                    <img src={arlaLogo} alt="arla logo" />
                    <img src={prsLogo} alt="prs logo" />
                    <img src={dpsLogo} alt="dps logo" />
                </div>
            </div>
        </div>
    );
}

export default Footer;