import { configureStore } from "@reduxjs/toolkit";
import favouritesReducer from "../features/favourites/favouritesSlice";
import filtersReducer from "../features/filters/filtersSlice";
import developmentsReducer from "../features/developments/developmentsSlice";
import accessTokenReducer from "../util/accessTokenSlice";
import mapzoomReducer from "../features/mapzoom/mapzoomSlice";

export default configureStore({
    reducer: {
        developments: developmentsReducer,
        favourites: favouritesReducer,
        filters: filtersReducer,
        accessToken: accessTokenReducer,
        mapzoom: mapzoomReducer,
    }
});