import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./DevelopmentSmall.scss";
import "./DevelopmentSmallMobile.scss";
import { SalesContext } from "../../../../context/SalesContext";
import { selectAccessToken } from "../../../../util/accessTokenSlice";
import {
  selectAllDevelopments,
  editFavInDevelopments,
} from "../../../../features/developments/developmentsSlice";
import {
  asyncPostFavourites,
  asyncDeleteFavourites,
  removeFavourite,
  addFavourite,
  setFavouritesAllStatus,
} from "../../../../features/favourites/favouritesSlice";
import houseIcon from "./house.svg";

const starYellowFilled = require("../../../../assets/media/icons/star-yellow-filled.png");
// const starGreyHollow = require("../../../../assets/media/icons/star-grey-hollow.png")
const starGreyHollow = require("../../../../assets/media/icons/star-light-grey-hollow.png");

function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../../../../assets/media/developmentImages",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

function DevelopmentSmall(props) {
  const { refresh, setRefresh } = useContext(SalesContext);
  const dispatch = useDispatch();
  const allDevelopments = useSelector(selectAllDevelopments);
  const accessToken = useSelector(selectAccessToken);

  const renderNum = (num) => {
    if (num == 1) {
      return "TBC";
    } else {
      return "£" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const imgStyle = (obj) => {
    return {
      backgroundImage: "url(" + images[obj["unique-id"] + ".jpg"] + ")",
      borderRadius: "8px 0 0 8px",
      backgroundClip: "padding-box",
      backgroundSize: "cover",
      overflow: "hidden",
    };
  };

  const clickFav = async (devId) => {
    let temp = allDevelopments;
    temp.map((item) => {
      if (item["unique-id"] === devId) {
        if (item["favourite"]) {
          dispatch(editFavInDevelopments({ id: devId, bool: false })); // delete from AllDevelopments
          dispatch(removeFavourite(devId)); // delete from allFavDevelopments
          dispatch(asyncDeleteFavourites(devId)); // delete from database
        } else {
          dispatch(editFavInDevelopments({ id: devId, bool: true })); // add to AllDevelopments
          dispatch(addFavourite(item)); // add to allFavDevelopments
          dispatch(asyncPostFavourites(devId)); // add to database
        }
      }
    });
    setRefresh(!refresh);
  };

  return (
    <div className="development_small_container">
      <div
        className={accessToken ? "button-fav" : "button-fav-hidden"}
        onClick={() => {
          clickFav(props.obj["unique-id"]);
        }}
      >
        {props.obj["favourite"] ? (
          <img src={starYellowFilled} />
        ) : (
          <img src={starGreyHollow} />
        )}
      </div>
      <div id="print-grid-dev">
        {/* <a id="print-grid-dev" href={props.obj['wechatlink']} target="_blank" style={{ "pointerEvents": "none", "cursor": "default" }} > */}
        <div className="grid_view_development" id={props.obj["unique-id"]}>
          <div className="flex-container with_img">
            {/* <div className="img-container" style={imgStyle(props.obj)}></div> */}
            <img
              className="grid-dev-img"
              src={images[props.obj["unique-id"] + ".jpg"]}
            />
            <div className="text-container">
              <div className="grid-dev-title">
                <h2>
                  {props.obj.name}&nbsp;
                  {props.obj.house && (
                    <img className="house-icon" src={houseIcon} />
                  )}
                </h2>
                <h2 language="zh">
                  {props.obj["name-chinese"]}&nbsp;
                  {props.obj.house && (
                    <img className="house-icon" src={houseIcon} />
                  )}
                </h2>
                {/* <h2>{props.obj.name}&nbsp;{props.obj.house && <span class="material-symbols-outlined little-house">house</span>}</h2>
                <h2 language="zh">{props.obj["name-chinese"]}&nbsp;{props.obj.house && <span class="material-symbols-outlined little-house">house</span>}</h2> */}
              </div>
              <h2 className="name-chinese">{props.obj["name-chinese"]}</h2>
              <p className="completion">
                <b>
                  <span language="en">Estimated completion: </span>
                  <span language="zh">预计交房时间：</span>
                </b>
                <span language="en">{props.obj["completion"]}</span>
                <span language="zh">
                  {props.obj["completion"] === "Completed"
                    ? "现房"
                    : props.obj["completion"]}
                </span>
              </p>
              <div className="flex-container with_text">
                <ul>
                  <li>
                    <b>Price from:</b>
                  </li>
                  {props.obj.studio && (
                    <li>
                      <b>
                        <span language="en">Studio: </span>
                        <span language="zh">开间：</span>
                      </b>
                      {renderNum(props.obj.studio)}
                    </li>
                  )}
                  {props.obj["one-bed"] && (
                    <li>
                      <b>
                        <span language="en">1 bed: </span>
                        <span language="zh">一室：</span>
                      </b>
                      {renderNum(props.obj["one-bed"])}
                    </li>
                  )}
                  {props.obj["two-bed"] && (
                    <li>
                      <b>
                        <span language="en">2 bed: </span>
                        <span language="zh">两室：</span>
                      </b>
                      {renderNum(props.obj["two-bed"])}
                    </li>
                  )}
                  {props.obj["three-bed"] && (
                    <li>
                      <b>
                        <span language="en">3 bed: </span>
                        <span language="zh">三室：</span>
                      </b>
                      {renderNum(props.obj["three-bed"])}
                    </li>
                  )}
                  {props.obj["four-bed"] && (
                    <li>
                      <b>
                        <span language="en">4 bed: </span>
                        <span language="zh">四室：</span>
                      </b>
                      {renderNum(props.obj["four-bed"])}
                    </li>
                  )}
                  {props.obj["five-bed"] && (
                    <li>
                      <b>
                        <span language="en">5 bed: </span>
                        <span language="zh">五室：</span>
                      </b>
                      {renderNum(props.obj["five-bed"])}
                    </li>
                  )}
                </ul>
                <ul>
                  <li>
                    <b>
                      <span language="en">Zone: </span>
                      <span language="zh">地铁区：</span>
                    </b>
                    {props.obj["zone-main"] <= 6
                      ? props.obj["zone-main"]
                      : "N/A"}{" "}
                    {props.obj["zone-other"] &&
                      props.obj["zone-other"] <= 6 &&
                      "/" + " " + props.obj["zone-other"]}
                  </li>
                  <li>
                    <b>
                      <span language="en">Postcode: </span>
                      <span language="zh">邮编：</span>
                    </b>
                    {props.obj.postcode ? props.obj.postcode : "N/A"}
                  </li>
                  <li>
                    <b>
                      <span language="en">Borough: </span>
                      <span language="zh">区域：</span>
                    </b>
                    {props.obj.borough}
                  </li>
                  <li>
                    <b>
                      <span language="en">Station: </span>
                      <span language="zh">最近车站：</span>
                    </b>
                    {props.obj.station} ({props.obj.distance}km)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopmentSmall;
