import React, { useEffect, useState, useLayoutEffect } from "react";
import "./DevelopmentOnMap.css";
import "./DevelopmentOnMapMobile.css";

// const fillShape = "#bca7e2";
// const fillShape = "#A6DECF";
const fillShape = "#DECDC8";
// const fillShape = "#c1b1de";
// const fillShape = "#c8bdd1";
// const fillShape = "rgba(250, 192, 156)";
const fillText = "black";
// const fillText = "#280844";
// const fillText = "rgb(19, 30, 49)";

function DevelopmentOnMap(props) {

    const [rectX, setRectX] = useState(0);
    const [rectY, setRectY] = useState(0);
    const [rectWidth, setRectWidth] = useState(0);
    const [rectHeight, setRectHeight] = useState(0);

    useEffect(() => {
        // TODO: 暂时用setTimeout凑活，因为隐藏的，所以这个会在grid页面被trigger，但是因为display: none，所以没法选中
        setTimeout(() => {
            if (document.querySelector(`#svg-container`)) {
                const { x: stationX, y: stationY, width: stationWidth, height: stationHeight } = document.querySelector(`#svg-container #idStationText${props.obj["unique-station-id"]}`).getBBox();
                const { x: devX, y: devY, width: devWidth, height: devHeight } = document.querySelector(`#svg-container #idDevText${props.obj["unique-station-id"]}`).getBBox();
                // const devCount = document.querySelector(`#svg-container #idDevText${props.obj["unique-station-id"]}`).childNodes.length;
                setRectX(stationX - 3);
                setRectY(stationY);
                setRectWidth(stationWidth >= devWidth ? stationWidth + 5 : devWidth + 5);
                // setRectHeight(stationHeight + devCount * 25);
                setRectHeight(stationHeight + devHeight + 3);
                // console.log(props.obj["unique-station-id"] + ": " + devCount);
                // const devInfo = document.querySelector(`#svg-container #idDevText${props.obj["unique-station-id"]}`);
                // devInfo.style.opacity = 0;
                // devInfo.style.display = "none";
                // devInfo.style.opacity = 1;
                // devInfo.style.display = "block";
            }
        }, 10);
    }, []);
    return (
        <g transform={props.obj.transform}>
            <rect className="shape-container" x={rectX} y={rectY} width={rectWidth} height={rectHeight} rx="4" fill={fillShape} />
            <a href="#" style={{ "pointerEvents": "none", "cursor": "default" }} >
                <text id={`idStationText${props.obj["unique-station-id"]}`} className="station-name" x="0" y="0" fill={fillText}>{props.obj["station-name"]}:</text>
            </a>
            <text className="dev-on-map" id={`idDevText${props.obj["unique-station-id"]}`} >
                {props.obj.developments.map((devInfo) => {
                    return (
                        // devInfo: [x.name, x["name-chinese"], x['unique-id'], x["wechatlink"], VALUE_FOR_Y, includeHouses]
                        <>
                            <a style={{ "pointerEvents": "none", "cursor": "default" }} href={devInfo[3] ? devInfo[3] : "#"} target={devInfo[3] ? "_blank" : ""} key={devInfo[0]}>
                                <>
                                    <tspan className="dev-names" x="0" y={devInfo[5]} fill={fillText}>
                                        {devInfo[0]} {devInfo[4] && "☗"}
                                    </tspan>
                                </>
                                {/* <path d="M10,20 L15,10 L20,20 L10,20 Z M12,20 V15 H18 V20 H12 Z" fill={fillText} /> */}
                                {/* <path d="M10,20 L15,10 L20,20 L10,20 Z M12,20 V15 H18 V20 H12 Z" transform={`translate(${0}, ${devInfo[4]})`} fill={fillText} /> */}
                                {/* <path fill={fillText} d="M200-160v-320H80l400-360 160 144v-104h120v212l120 108H760v320H560v-240H400v240H200Zm200-399h160q0-32-24-52.5T480-632q-32 0-56 20.5T400-559Z" /> */}


                                {/* <tspan className="dev-names" x="0" y={devInfo[4]} fill={fillText} >
                                {devInfo[0]}
                            </tspan>
                            <path fill={fillText} d="M200-160v-320H80l400-360 160 144v-104h120v212l120 108H760v320H560v-240H400v240H200Zm200-399h160q0-32-24-52.5T480-632q-32 0-56 20.5T400-559Z" /> */}
                                {/* <tspan className="dev-names dev-names-chinese" x="0" y={devInfo[4] + 11.4} fill={fillText}>
                                {devInfo[1]}
                            </tspan> */}
                                {/* TODO: 不打印不写英文 */}
                            </a>
                        </>

                    );
                })}
            </text>
        </g >
    );
}

export default DevelopmentOnMap;
