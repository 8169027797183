import React, { useState } from "react";
import "./Reports.css";
import "./ReportsMobile.css";

const reportReport = require("../../../assets/media/reports/report-report.png");
const reportTrend = require("../../../assets/media/reports/report-trend.png");
const reportComparison = require("../../../assets/media/reports/report-comparison.png");
const reportSafety = require("../../../assets/media/reports/report-safety.png");
const reportMap = require("../../../assets/media/reports/report-map.png");
const reportROI = require("../../../assets/media/reports/report-roi.png");

function Reports() {
    const [reports, setReports] = useState([
        ["Property Search and Appraisal Report", reportReport, "report-visible"],
        ["Return On Investment Analysis", reportROI, "report-invisible"],
        ["Regional safety and social personnel analysis", reportSafety, "report-invisible"],
        ["Property evaluation comparison", reportComparison, "report-invisible"],
        ["House Price Trend Analysis", reportTrend, "report-invisible"],
        ["25-dimensional map of comprehensive assessment", reportMap, "report-invisible"]
    ]);

    const [re, setRe] = useState("xfdaeax");
    // console.log()

    var slideIndex = 0;
    function loadFirst() {
        showDivs(slideIndex);
    }
    // // setInterval(plusDivs(slideIndex + 1), 2000);
    function plusDivs(n) {
        showDivs(slideIndex += n);
    }

    function showDivs(n) {
        console.log(`n is ${n}`);
        console.log("in showdivs");
        // if (n = reports.length) { slideIndex = 0 }
        // if (n < 0) { slideIndex = reports.length - 1 }
        // slideIndex++;
        const newArr = [...reports];
        for (let i = 0; i < reports.length; i++) {
            console.log("in loop");
            console.log(`i is ${i}, n is ${n}`);
            // reports[i].style.display = "none";
            if (i === n) {
                newArr[i][2] = "report-visible";
            }
            else {
                newArr[i][2] = "report-invisible";
            }
        }
        console.log(newArr);
        setReports(newArr);
    }


    return (
        <div>
            <button onClick={() => plusDivs(-1)}>&#10094;</button>
            <div className="reports">
                {[...reports].map(i => {
                    return <img src={i[1]} className={i[2]} />;
                })}
            </div >
            {/* <p>ds{re}</p> */}
            <button onClick={() => plusDivs(1)} >&#10095;</button>
        </div >
    );
}

export default Reports;