import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFavourites, postFavourites, deleteFavourites, getFavDevelopments } from "../../api/developments";

// INITIAL STATE
const initialState = {
    favourites: {
        favDevIndex: [], // not used yet
        favDevAll: []
    },
    status: {
        allFav: "idle", // not used yet
        allFavDev: "idle"
    }, // TODO: fav和favdev不能共用!!!
    error: null
};

// ASYNC THUNKS
export const asyncFetchFavourites = createAsyncThunk("favourites/fetchFavourites", async () => {
    const favourites = await getFavourites();
    return favourites;
}); // not used yet

export const asyncPostFavourites = createAsyncThunk("favourites/postFavourites", async (devId) => {
    const response = await postFavourites(devId);
    return response;
});

export const asyncDeleteFavourites = createAsyncThunk("favourites/deleteFavourites", async (devId) => {
    const response = await deleteFavourites(devId);
    return response;
});

export const asyncFetchFavDevelopments = createAsyncThunk("favourites/fetchFavDevelopments", async () => {
    const developments = await getFavDevelopments();
    return developments;
});

// SLICE OBJECT
export const favouritesSlice = createSlice({
    name: "favourites",
    initialState,
    reducers: {
        addFavourite: (state, action) => {
            state.favourites.favDevAll.push({
                ...action.payload,
                favourite: true
            });
            console.log(state.favourites.favDevAll);
        },
        removeFavourite: (state, action) => {
            state.favourites.favDevAll = state.favourites.favDevAll.filter(dev => dev['unique-id'] !== action.payload);
        },
        editFavInFavourites: (state, action) => {
            const { id, bool } = action.payload;
            const dev = state.favourites.favDevAll.find((d) => d['unique-id'] === id);
            if (dev) {
                dev.favourite = bool;
            }
        },
        setFavouritesAllStatus: (state, action) => {
            state.status.allFavDev = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchFavourites.pending, (state) => {
                state.status.allFav = "loading";
            })
            .addCase(asyncFetchFavourites.fulfilled, (state, action) => {
                state.status.allFav = "succeeded";
                state.favourites.favDevIndex = action.payload;
            })
            .addCase(asyncFetchFavourites.rejected, (state) => {
                state.status.allFav = "failed";
            })
            .addCase(asyncPostFavourites.fulfilled, (state, action) => {
                state.favourites.favDevIndex.push(action.payload);
            })
            .addCase(asyncDeleteFavourites.fulfilled, (state, action) => {
                state.favourites.favDevIndex.filter(item => item.id !== action.payload.id); //!!!! id?
            })
            .addCase(asyncFetchFavDevelopments.fulfilled, (state, action) => {
                state.status.allFavDev = "succeeded";
                state.favourites.favDevAll = action.payload;
            })
            .addCase(asyncFetchFavDevelopments.pending, (state) => {
                state.status.allFavDev = "loading";
            })
            .addCase(asyncFetchFavDevelopments.rejected, (state) => {
                state.status.allFavDev = "failed";
            });
    }
});



// SELECTORS
export const selectFavourites = (state) => state.favourites.favourites.favDevIndex; // not used yet
export const selectFavDevelopments = (state) => state.favourites.favourites.favDevAll;


// EXPORTS
export const { removeFavourite, addFavourite, setFavouritesAllStatus } = favouritesSlice.actions;

export default favouritesSlice.reducer;
