import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from 'react-router-dom';
import "./FrontPage.scss";
import "./FrontPageMobile.scss";
import Partners from "../FrontPage/Partners/Partners";
import Services from "./Services/Services";
import { SalesContext } from "../../context/SalesContext";
import { NavLink } from "react-router-dom";
import Reports from "../FrontPage/Reports/Reports";
import rightArrow from "../../assets/media/icons/right-arrow.svg";
import Contact from "../Contact/Contact";
import Insights from "./Insights/Insights";

import { ReactComponent as house } from "../../assets/media/icons/svg/building.svg";
import { ReactComponent as manage } from "../../assets/media/icons/svg/document.svg";
import { ReactComponent as loan } from "../../assets/media/icons/svg/bank.svg";
import { ReactComponent as lawyer } from "../../assets/media/icons/svg/law.svg";
import { ReactComponent as tax } from "../../assets/media/icons/svg/pound.svg";
import { ReactComponent as school } from "../../assets/media/icons/svg/school.svg";

const londonIllustration = require("../../assets/media/decoration/london-illustration.png");

function FrontPage(props) {
    const { lang, loadLang } = useContext(SalesContext);
    const service =
        [
            { img: loan, en: "Bank Loan", zh: "银行贷款" },
            { img: house, en: "House Selecting", zh: "房源挑选" },
            { img: lawyer, en: "Lawyer", zh: "律师推荐" },
            { img: manage, en: "Management", zh: "租赁管理" },
            { img: tax, en: "Tax Planning", zh: "税务规划" },
            { img: school, en: "School", zh: "子女入学" },
        ];

    const targetRef = useRef(null);
    const location = useLocation();
    const scrollToContact = location.state && location.state.scrollToContact;

    useEffect(() => {
        if (scrollToContact && targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [scrollToContact]);
    useEffect(() => {
        loadLang(lang);
        // let logoText = document.querySelector('.front-text-container > p.showLanguage');
        // setTimeout(() => {
        //     logoText.style.opacity = 1;
        //     logoText.style.transform = "translateY(0vh)";
        // }, 1);
        let i = document.querySelector(".services-title").getBoundingClientRect(); //TODO: workaround for sometimes some text doesn't show on frontpage when logo is clicked; this results in a repaint
    });

    return (
        <div className="front-container">
            <section className="front">
                <div>
                    <p language="en" className="home-slogen font-special"><span>Elegant Living, </span><br /><span>Confident Investing</span></p>
                    <p language="zh" className="home-slogen font-special"><span>优雅生活，</span><span>自信投资</span></p>
                    <p language="en" className="home-slogen-text">Whether it is a primary residence, holiday home or investment, we are here to help you to find the right property in the right location.</p>
                    <p language="zh" className="home-slogen-text">无论是主要住宅、度假屋还是投资房产，我们都致力于帮助您找到合适的房产和理想的地点。</p>
                    <a className="front-contact-us-button font-weight-600" href="/#contact-us">
                        <span language="en">Contact Us</span>
                        <span language="zh">联系我们</span>
                    </a>
                </div>
                <img src={londonIllustration} alt="london illustartion art background" />
            </section>
            <main className="trigger-menu-wrapper">
                <div className="introduction">
                    <div className="intro-title">
                        <p language="en">About NVRE</p>
                        <p language="zh">关于 NVRE</p>
                    </div>
                    <div className="intro-text">
                        <p language="en">NVRE is a top-tier real estate business consulting firm based in central London, certified by the UK's foremost real estate regulator - "ARLA Propertymark". Our team comprises Cambridge University graduates with over 15 years of senior management and professional experience in real estate and asset management.</p>
                        <p language="zh">NVRE 是一家总部位于英国伦敦的高端地产咨询公司，受英国权威房产监管机构ARLA Propertymark认证，为高净值客户及家族企业提供专业高效的地产交易及租赁管理服务。我们的创始团队成员均毕业于剑桥大学，在英国的地产置业领域拥有多年的管理经验。专业性是广大客户选择我们的首要因素。</p>
                        <a className="button-learnmore" href="./about">
                            <span language="en">Learn More</span>
                            <span language="zh">了解更多</span>
                        </a>
                    </div>
                </div>
                <Services service={service} />
                {/* <Insights /> */}
                <Partners />
                <div id="front-contact-us" ref={targetRef}>
                    <Contact />
                </div>
            </main>

        </div>
    );
}

export default FrontPage;