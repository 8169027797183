import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Button, Slider, Checkbox, FormGroup, FormControlLabel, TextField, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SalesContext } from "../../../context/SalesContext";
import { loginUser } from "../../../api/users";
import { setDevelopmentStatus, asyncFetchDevelopments } from "../../../features/developments/developmentsSlice";
import FormInput from "../../../components/Form/FormInput/FormInput";
import FormCheckbox from "../../../components/Form/FormRadio/FormCheckbox";

const emailIcon = require("../../../assets/media/icons/email1.png");
const lockIcon = require("../../../assets/media/icons/lock1.png");

const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        neutral: {
            main: 'rgba(250, 186, 142, 255)',
            contrastText: '#fff',
            borderColor: 'rgba(250, 186, 142, 255)'
        },
    },
});

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginRemember, setLoginRemember] = useState(false);
    const dispatch = useDispatch();
    const { refresh, setRefresh } = useContext(SalesContext);
    let navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <div className="login-container">
                <form id="form-login" action="/login" method="POST">
                    <FormInput input={{ class: "static", label: "Email", type: "txt", value: email, img: emailIcon, update: (value) => { setEmail(value); } }} />
                    <FormInput input={{ class: "static", label: "Password", type: "password", value: password, img: lockIcon, update: (value) => { setPassword(value); } }} />
                    <div className="login-rmbr-forgot">
                        <FormCheckbox input={{ class: "", label: "Remember me", type: "checkbox", value: loginRemember, update: (value) => { setLoginRemember(value); } }} />
                        <a href="#"><span language="en">Forgotten your password?</span><span language="zh">忘记密码</span></a>
                    </div>
                    <Button id="login-button" variant="outlined" color="neutral" onClick={async e => {
                        e.preventDefault();
                        if (!email || !password) {
                            return;
                        }
                        const success = await loginUser(email, password);
                        if (success) {
                            setEmail("");
                            setPassword("");
                            dispatch(setDevelopmentStatus("idle"));
                            dispatch(asyncFetchDevelopments());
                            setRefresh(!refresh);
                            setTimeout(() => {

                                navigate("/");
                            }, 0);
                        } else {
                            console.log("login failed");
                        }
                    }}><span language="en">Login</span><span language="zh">登陆</span></Button>
                </form>
            </div>
        </ThemeProvider>
    );
};