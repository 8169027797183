import React, { useContext, useEffect, useState } from "react";
import "./Rent.scss";
import "./RentMobile.scss";
import { SalesContext } from "../../context/SalesContext";
import rentBackground from "../../assets/media/decoration/buildings.svg";

import { ReactComponent as archive } from "../../assets/media/icons/rent/archive.svg";
import { ReactComponent as credit } from "../../assets/media/icons/rent/credit.svg";
import { ReactComponent as document } from "../../assets/media/icons/rent/document.svg";
import { ReactComponent as money } from "../../assets/media/icons/rent/money.svg";
import { ReactComponent as search } from "../../assets/media/icons/rent/search.svg";
import { ReactComponent as security } from "../../assets/media/icons/rent/security.svg";
import { ReactComponent as calculate } from "../../assets/media/icons/rent/calculate.svg";
import { ReactComponent as check } from "../../assets/media/icons/rent/check.svg";
import { ReactComponent as clean } from "../../assets/media/icons/rent/clean.svg";
import { ReactComponent as maintenance } from "../../assets/media/icons/rent/maintenance.svg";
import { ReactComponent as suitcase } from "../../assets/media/icons/rent/suitcase.svg";
import { ReactComponent as tools } from "../../assets/media/icons/rent/tools.svg";
const priceListEnglish = require("../../assets/file/NVRE LETTING PROCEDURE & PRICE LIST.pdf");
const priceListChinese = require("../../assets/file/NVRE租赁流程及费用清单.pdf");
const rentingServices = {
    pre: [
        { img: search, nameEN: "Matching", nameZH: "匹配", descEN: "Property listings and finding the most suitable tenants", descZH: "登房产信息和寻找最合适的租户" },
        { img: security, nameEN: "Security Check", nameZH: "安全检查", descEN: "Arrange all required security checks on your behalf in accordance with UK housing regulations", descZH: "遵循英国住房法规安排所有必要的安全检查" },
        { img: money, nameEN: "Deposit", nameZH: "押金", descEN: "Register the deposit paid by the tenant during the tenancy period on your behalf in accordance with the relevant legal provisions", descZH: "根据相关法律规定，代您登记承租人在租赁期间支付的押金" },
        { img: credit, nameEN: "Credit Check", nameZH: "信用调查", descEN: "Financial credit checks to ensure the tenant's ability to pay", descZH: "财务信用检查以确保租户的支付能力" },
        { img: document, nameEN: "Tenancy Agreement", nameZH: "合同", descEN: "Drafting and formulating tenancy agreement in accordance with the terms of UK housing legislation", descZH: "根据英国住房立法的条款起草和制定租赁协议" },
        { img: archive, nameEN: "Inventories", nameZH: "家具库存", descEN: "Arranging move-in/move-out inventories, and producing inventory reports", descZH: "安排家具，并生成库存报告" },
    ],
    during: {
        maintenance: [
            { img: maintenance, nameEN: "Inspections", nameZH: "检阅", descEN: "Regular property maintenance inspections", descZH: "定期房屋维护检查" },
            { img: tools, nameEN: "Repair", nameZH: "维修", descEN: "Emergency repair services", descZH: "紧急维修服务" },
            { img: clean, nameEN: "Cleaning", nameZH: "卫生", descEN: "Organize professional cleaning and maintenance services", descZH: "安排专业的清洁和维护服务" },
            { img: money, nameEN: "Rent Guarantee", nameZH: "租金保障", descEN: "Agent rental guarantee insurance service for landlords if required", descZH: "如需可为房东代办租房保障保险服务" },
        ],
        finance: [
            { img: suitcase, nameEN: "Rent", nameZH: "租金", descEN: "Rent collection service", descZH: "收租服务" },
            // { img: check, nameEN: "Tax - free", nameZH: "免税", descEN: "Tax-free application arrangements for rental income of overseas owners", descZH: "为海外业主租金收入免税申请安排" },
            { img: calculate, nameEN: "Income Tax", nameZH: "所得税", descEN: "Assist owners with annual tax and rental income calculations", descZH: "协助业主计算年度税收和租金收入" },

        ]
    }
};
export default function Rent() {
    const { lang } = useContext(SalesContext);
    const { loadLang } = useContext(SalesContext);
    const [preLeasingPresenting, setPreLeasingPresenting] = useState(true);

    const showPreLeasing = () => {
        setPreLeasingPresenting(true);
    };

    const showDuringLeasing = () => {
        setPreLeasingPresenting(false);
    };

    useEffect(() => {
        loadLang(lang);
    });

    return (
        <div className="rent-container">
            <div className="rent-intro">
                <div language="en" className="rent-intro-text">
                    <h3 className="font-special">To Rent</h3>
                    <p>As a certified ARLA member, NVRE provide local and overseas landlords with one-stop-shop services, including property handover, contract generation, inventory report, rent collection, and property management. Our specialist letting team in London provide you with top-quality services to ease the pressure you may encounter in property letting and management. Using our extensive network and innovative marketing techniques, we are able to efficiently connect landlords and suitable tenants in the shortest time possible. </p>
                    <br />
                    <p>Property management is the main responsibility of the landlord. Under the UK Landlord and Tenant Clause (1985), tenants have the right to keep the rent if the landlord fails to make timely repairs in certain respects.                    </p>
                </div>
                <div language="zh" className="rent-intro-text">
                    <h3 className="font-special">房东租赁</h3>
                    <p>作为ARLA认证会员，NVRE 为本地和海外业主提供一站式服务，包括房产交接、合同生成、库存报告、收租和物业管理服务。 我们在伦敦的专业出租团队将为您提供最优质的服务，以减轻您在物业出租和管理中可能遇到的压力。 运用我们广泛的网络和创新的营销技术，我们能够在尽可能短的时间内有效地联系房东和合适的租户。</p>
                    <br />
                    <p>房屋管理是房东的主要责任。 根据英国房东和租客条款（1985），如果房东未能在某些方面及时维修，租客有权保留租金。</p>
                </div>
                <img src={rentBackground} alt="building illustartion for decoration" />
            </div>
            <div className="rent-services-intro">
                <h3 language="en">Services</h3>
                <h3 language="zh">服务</h3>
                <p language="en">Our company provides comprehensive services to assist clients with all aspects of leasing. We understand that leasing a property can be a complex and daunting process, which is why we strive to make it as easy and stress-free as possible for our clients. Our services are designed to help clients before and during the leasing process.</p>
                <p language="zh">我们公司提供全面的服务，帮助客户处理租赁的各个方面。我们理解租赁物业可能是一个复杂而令人畏惧的过程，因此我们努力使其对客户来说尽可能简单和无压力。我们的服务旨在帮助客户在租赁过程之前和期间。</p>
            </div>
            <div className="rent-services">
                <div className="pre-leasing-services">
                    <div className="renting-subtitle">
                        <h3 className="title" language="en">
                            <p className="title-main">Services Pre Leasing</p>
                            <p className="title-sub">Getting it ready</p>
                        </h3>
                        <h3 className="title" language="zh">
                            <p className="title-main">预租服务</p>
                            <p className="title-sub">准备好出租</p>
                        </h3>
                        <div className="deco-dash"></div>
                    </div>
                    <div className="pre-leasing-services-list renting-service-list">
                        <div className="service-items">
                            {rentingServices.pre.map(item => {
                                return (
                                    <div className="service-item" key={item.nameEN}>
                                        {/* <img src={item.img} alt={`icon for ${item.nameEN}`} /> */}
                                        <item.img />
                                        <h4 language="en">{item.nameEN}</h4>
                                        <h4 language="zh">{item.nameZH}</h4>
                                        <p language="en">{item.descEN}</p>
                                        <p language="zh">{item.descZH}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="during-leasing-services">
                    <div className="renting-subtitle">
                        <h3 className="title" language="en">
                            <p className="title-main">Services During Leasing</p>
                            <p className="title-sub">We take care of everything</p>
                        </h3>
                        <h3 className="title" language="zh">
                            <p className="title-main">租赁期间服务</p>
                            <p className="title-sub">我们负责所有事务</p>
                        </h3>
                        <div className="deco-dash"></div>
                    </div>
                    <div className="during-leasing-services-list renting-service-list">
                        <div className="rent-service-maintenance rent-service-services">
                            <h4 className="during-leasing-services-title">
                                <span language="en">Maintenance</span>
                                <span language="zh">维护</span>
                            </h4>
                            <div className="service-items">
                                {rentingServices.during.maintenance.map(item => {
                                    return (
                                        <div className="service-item" key={item.nameEN}>
                                            <item.img />
                                            <div>
                                                <h4 language="en">{item.nameEN}</h4>
                                                <h4 language="zh">{item.nameZH}</h4>
                                                <p language="en">{item.descEN}</p>
                                                <p language="zh">{item.descZH}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="rent-service-finance rent-service-services">
                            <h4 className="during-leasing-services-title">
                                <span language="en">Finances</span>
                                <span language="zh">财政</span>
                            </h4>
                            <div className="service-items">
                                {rentingServices.during.finance.map(item => {
                                    return (
                                        <div className="service-item" key={item.nameEN}>
                                            <item.img />
                                            <div>
                                                <h4 language="en">{item.nameEN}</h4>
                                                <h4 language="zh">{item.nameZH}</h4>
                                                <p language="en">{item.descEN}</p>
                                                <p language="zh">{item.descZH}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rent-white-transparent-divider"></div>
            <div className="price-list">
                <h2>Letting Procedure and Price list</h2>
                <p>Please find it <a href={priceListEnglish} target="_blank" style={{ textDecoration: "underline" }}>here [English]</a> and <a href={priceListChinese} target="_blank" style={{ textDecoration: "underline" }}>here [Chinese]</a>.</p>
            </div>
            <div className="rent-white-transparent-divider"></div>
        </div>
    );
};