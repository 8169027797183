import { API_ENDPOINT } from ".";
import store from "../app/store";
import { selectAccessToken } from "../util/accessTokenSlice";

export const getDevelopments = async () => {
    const response = await fetch(`${API_ENDPOINT}/developments`, {
        method: "GET",
        credentials: "include"
    });
    const developments = await response.json();

    // return developments.map(dev => {

    // });


    // console.log(developments);
    // downloadCSV(developments);
    return developments;
};

const updateDataBasedOnDate = (data, currentQuarter, currentYear) => {
    return data.map(item => {
        let [start, end] = item.split(' - ');
        if (end) {
            if (parseInt(end) <= currentYear) {
                return 'completed';
            }
        } else {
            end = start;
        }

        const [qEnd, yearEnd] = end.split(' ');

        if (qEnd === 'Q1' && yearEnd && parseInt(yearEnd) === currentYear + 1) {
            if (currentQuarter === 'Q4') {
                return 'completed / ' + end;
            }
        } else if (qEnd === 'Q4' && yearEnd && parseInt(yearEnd) === currentYear) {
            if (currentQuarter === 'Q4') {
                return 'completed';
            }
        } else if (item.startsWith('From')) {
            if (currentQuarter === qEnd && parseInt(yearEnd) === currentYear) {
                return 'From completed';
            }
        }

        return item;
    });
};

function downloadCSV(arrayOfObjects) {
    // List of specific columns in the desired order
    const columns = [
        "unique-id", "studio", "five-bed", "distance", "zone-main", "source",
        "borough", "lowest-price", "unique-station-id", "completion-array",
        "three-bed", "four-bed", "two-bed", "station", "wechatid", "six-bed",
        "completion", "in-map", "postcode", "line-num", "in-svg", "zone-other",
        "highest-price", "one-bed", "name", "name-chinese", "developer", "total-units",
        "wechatlink"
    ];

    // Convert the array of objects to CSV format
    const csvContent = arrayOfObjects.map(obj => {
        return columns.map(col => {
            const val = obj[col];

            // Check for falsy values and leave them blank
            if (!val) {
                return '';
            }

            // Wrap strings with commas or quotes in quotes, escaping internal quotes
            if (typeof val === 'string' && (val.includes(',') || val.includes('"'))) {
                return '"' + val.replace(/"/g, '""') + '"';
            }
            return val;
        }).join(',');
    }).join('\n');

    // Create the CSV headers based on the columns array
    const headers = columns.join(',');
    const csv = headers + '\n' + csvContent;

    // Create a blob from the CSV content
    const blob = new Blob([csv], { type: 'text/csv' });

    // Use window.navigator.msSaveBlob for IE/Edge support
    if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, 'data.csv');
    } else {
        // For other browsers
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = 'data.csv';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
}



export const getFavDevelopments = async () => {
    const accessToken = selectAccessToken(store.getState());
    const response = await fetch(`${API_ENDPOINT}/favdevelopments`, {
        method: "GET",
        credentials: "include",
        headers: {
            authorization: accessToken ? `bearer ${accessToken}` : "",
            'Content-Type': 'application/json'
        },
    });
    const developments = await response.json();
    developments.map(i => {
        i["favourite"] = true;
    });
    return developments;
};

export const getFavourites = async () => {
    console.log("get fav 1");
    const accessToken = selectAccessToken(store.getState());
    console.log("get fav 2");
    const response = await fetch(`${API_ENDPOINT}/favourites`, {
        method: "GET",
        credentials: "include",
        headers: {
            authorization: accessToken ? `bearer ${accessToken}` : "",
            'Content-Type': 'application/json'
        }
    });
    console.log("get fav 3");
    console.log(response);
    const favourites = await response.json();
    console.log("get fav 4");
    return favourites;
};

export const postFavourites = async (devId) => {
    const accessToken = selectAccessToken(store.getState());
    const response = await fetch(`${API_ENDPOINT}/favourites`, {
        method: "POST",
        credentials: "include",
        headers: {
            authorization: accessToken ? `bearer ${accessToken}` : "",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ devId: devId })
    });

    const favourites = await response.json();
    return favourites;
};

export const deleteFavourites = async (devId) => {
    const accessToken = selectAccessToken(store.getState());
    await fetch(`${API_ENDPOINT}/favourites`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            authorization: accessToken ? `bearer ${accessToken}` : "",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ devId: devId })
    });

};

