import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ViewControlView.scss";
import "./ViewControlViewMobile.scss";
import { Select, FormControl, MenuItem, InputLabel, Button, TextField, InputAdornment } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { clearFilter } from "../../../features/filters/filtersSlice";
import { selectAccessToken } from "../../../util/accessTokenSlice";
import { printAsPDF } from "../../../util/pdfRelated";
import { SalesContext } from "../../../context/SalesContext";
import FilterView from "../Filter/FilterView";

const searchIcon = require("../../../assets/media/icons/search2.png");
const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#0971f1',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            // main: 'rgba(250, 186, 142, 255)',
            contrastText: '#fff',
        },
    },
});

export const ViewControlView = (props) => {
    const dispatch = useDispatch();
    const accessToken = useSelector(selectAccessToken);
    const { isMobile, showFilter, setShowFilterStatus, isWidthHideFilter } = useContext(SalesContext);
    const [openFilter, setOpenFilter] = useState(false);

    const setView = (view) => {
        props.setGrid(view === "list" ? true : false);
    };

    const toggleFavourites = async (e) => {
        console.log("in");
        props.setShowFav(props.showFav ? false : true);
        dispatch(clearFilter());
        console.log("end");

    };

    const printMap = () => {
        console.log("clicked print");
        window.print();
        // printAsPDF(document.getElementById("map-to-print"), "grid");
    };

    return (
        <div className={props.showFilter ? "view-control-container hide" : "view-control-container"}>
            <div className="left">
                <p className="results">{props.count > 9 ? props.count : <span>{props.count}&nbsp;&nbsp;</span>} <span language="en">results</span><span language="zh">个楼盘</span></p>
                <div className="divider"></div>
                <ThemeProvider theme={theme}>
                    {/* <Button variant="outlined" color="neutral" >FAVOURITES</Button> */}
                    {/* <Button className={accessToken ? "button-show-fav" : "button-show-fav-hidden"} variant="outlined" color="neutral" onClick={toggleFavourites}>{props.showFav ? "HIDE FAVOURITES" : "SHOW FAVOURITES"}</Button> */}
                    <Button className={accessToken ? "button-show-fav" : "button-show-fav-hidden"} variant="outlined" color="neutral" onClick={toggleFavourites}>
                        <span language="en">{props.showFav ? "HIDE FAVOURITES" : "SHOW FAVOURITES"}</span>
                        <span language="zh">{props.showFav ? "隐藏收藏" : "显示收藏"}</span>
                    </Button>
                </ThemeProvider>
                {props.grid && (
                    <>
                        <div language="en" className="sort-container">
                            <div className="sort-by">Sort by: </div>
                            <FormControl fullWidth size="small">
                                <Select
                                    id="sort-by-select"
                                    value={props.sort}
                                    onChange={props.onChange}
                                    displayEmpty
                                >
                                    <MenuItem value={"name"}>Name</MenuItem>
                                    <MenuItem value={"highest-price"}>Highest price</MenuItem>
                                    <MenuItem value={"lowest-price"}>Lowest price</MenuItem>
                                    <MenuItem value={"zone"}>Zone</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div language="zh" className="sort-container">
                            <div className="sort-by">排序方式：</div>
                            <FormControl fullWidth size="small">
                                <Select
                                    id="sort-by-select"
                                    value={props.sort}
                                    onChange={props.onChange}
                                    displayEmpty
                                >
                                    <MenuItem value={"name"}>名称</MenuItem>
                                    <MenuItem value={"highest-price"}>最高价格</MenuItem>
                                    <MenuItem value={"lowest-price"}>最低价格</MenuItem>
                                    <MenuItem value={"zone"}>地铁区</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </>
                )}
            </div>
            <div className="search-and-fav-container">
                <TextField
                    id="search-by-name"
                    // label="To"
                    variant="outlined"
                    size="small"
                    value={props.searchName}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" disableTypography><img src={searchIcon} style={{ height: "17px" }} /></InputAdornment>,
                    }}
                    onChange={props.searchByName}
                />
                {isWidthHideFilter && (
                    <>
                        <div className="filter-icon" onClick={() => { setShowFilterStatus(true); }}>
                            <span className="material-symbols-outlined">tune</span>
                        </div>
                        {/* {openFilter &&
                            <div className="control-view-filter-container">
                                <FilterView
                                    setPriceRange={props.setPriceRange}
                                    updateData={props.updateData}
                                    renderWithSort={props.renderWithSort}
                                    renderSVG={props.renderSVG}
                                    sort={props.sort}
                                    showFav={props.showFav}
                                    minMax={props.minMax}
                                    showFilter={props.showFilter}
                                    mobileShowFilter={props.mobileShowFilter}
                                    stopFilterFixed={props.stopFilterFixed}
                                    filterMaxHeight={props.filterMaxHeight}
                                />
                            </div>
                        } */}
                    </>
                )}
            </div>
            <div className="view-control-container-sub">
                <div className="view-control">
                    <div className={props.grid ? "view-container chosen" : "view-container"}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 17" height="2rem"><path d="M4.846.209C1.93.925 0 3.503 0 6.512 0 7.174.09 7.8.268 8.374c.179.644.465 1.235.84 1.772.09.144 3.309 4.566 4.74 6.518a.82.82 0 001.323 0c1.43-1.952 4.667-6.392 4.756-6.553a5.93 5.93 0 00.805-1.737A6.42 6.42 0 0013 6.512C13.018 2.357 9.155-.866 4.846.209zm1.663 9.185A2.879 2.879 0 013.63 6.512a2.879 2.879 0 115.758 0 2.879 2.879 0 01-2.88 2.882z"></path></svg> */}
                        <button id="button-set-view" className="view-control" onClick={() => { setView("list"); }}><span language="en">List view</span><span language="zh">表格</span></button>
                    </div>
                    <div className="divider"></div>
                    <div className={props.grid ? "view-container" : "view-container chosen"} onClick={() => { setView("map"); }}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 17" height="2rem"><path d="M4.846.209C1.93.925 0 3.503 0 6.512 0 7.174.09 7.8.268 8.374c.179.644.465 1.235.84 1.772.09.144 3.309 4.566 4.74 6.518a.82.82 0 001.323 0c1.43-1.952 4.667-6.392 4.756-6.553a5.93 5.93 0 00.805-1.737A6.42 6.42 0 0013 6.512C13.018 2.357 9.155-.866 4.846.209zm1.663 9.185A2.879 2.879 0 013.63 6.512a2.879 2.879 0 115.758 0 2.879 2.879 0 01-2.88 2.882z"></path></svg> */}
                        <button id="button-set-view" className="view-control" ><span language="en">Map view</span><span language="zh">地铁图</span></button>
                    </div>
                </div>
                {!isMobile &&
                    <button className="button-map-print" onClick={printMap}>PRINT</button>
                }
            </div>
        </div >
    );
}

