import React, { useState, useEffect, useContext } from "react";
import { Map } from "../pages/Sale/Sale";
import FrontPage from "../pages/FrontPage/FrontPage";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import Rent from "../pages/Rent/Rent";
import About from "../pages/About/About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RegisterLoginPage from "../pages/RegisterLogin/RegisterLogin";
import { SalesContext } from "../context/SalesContext";
import StampDutyPage from "../pages/Services/StampDutyPage/StampDutyPage";
import RentalYieldPage from "../pages/Services/YieldPage/YieldPage";
import RentEstimatePage from "../pages/Services/RentEstimatePage/RentEstimatePage";
import MortgagePage from "../pages/Services/MorgagePage/MortgagePage";

export const AppRoutes = () => {

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route
            path="/let"
            element={
              <>
                <Header page="rent" darkBackground={false} />
                <Rent />
                <Footer showFooter={true} />
              </>
            }
          />
          <Route path="/services/stamp-duty-calculator" element={
            <>
              <Header page="services" darkBackground={false} />
              <StampDutyPage />
              <Footer showFooter={true} />
            </>
          } />
          <Route path="/services/rental-yield" element={
            <>
              <Header page="services" darkBackground={false} />
              <RentalYieldPage />
              <Footer showFooter={true} />
            </>
          } />
          <Route path="/services/rent-estimate" element={
            <>
              <Header page="services" darkBackground={false} />
              <RentEstimatePage />
              <Footer showFooter={true} />
            </>
          } />
          <Route path="/services/mortgage-calculator" element={
            <>
              <Header page="services" darkBackground={false} />
              <MortgagePage />
              <Footer showFooter={true} />
            </>
          } />

          <Route
            path="/about"
            element={
              <>
                <Header page="about" darkBackground={false} />
                <About />
                <Footer showFooter={true} />
              </>
            }
          />
          <Route
            path="/property"
            element={
              <>
                <table>
                  <thead>
                    <Header page="tube" darkBackground={true} />
                  </thead>
                  <tbody>
                    <Map page="map" />
                  </tbody>
                  <tfoot>
                    <p id="globalvision">Elegant Living, Confident Investing</p>
                    <p id="copyright">
                      &#169; Copyright reserved. Designed by NVRE
                    </p>
                  </tfoot>
                </table>
                <Footer showFooter={true} />
              </>
            }
          />
          <Route
            // TODO: change back to "login"
            path="/login"
            element={
              <>
                <Header page="login" darkBackground={false} />
                <RegisterLoginPage />
                <Footer showFooter={false} />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Header page="front" darkBackground={false} />
                <FrontPage />
                <Footer showFooter={true} />
              </>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};
