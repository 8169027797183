import React, { useEffect, useContext, useState } from "react";
import "./RegisterLogin.css";
import "./RegisterLoginMobile.css";
import { Login } from "./Login/Login";
import { Register } from "./Register/Register";
import { SalesContext } from "../../context/SalesContext";

const backgroundImg = require("../../assets/media/background/background7.jpg");
function RegisterLoginPage() {
    const { lang } = useContext(SalesContext);
    const { loadLang } = useContext(SalesContext);
    const [isLoginPage, setIsLoginPage] = useState(true);

    useEffect(() => {
        loadLang(lang);
    });

    return (
        <div className="register-login-container">
            <p>Whether it is a primary residence, <br />holiday home or investment, <br />we are here to help you to <br />find the right property in the right location.</p>
            <img src={backgroundImg} />
            <div className="register-login-content">
                <div className="register-login-label">
                    {isLoginPage
                        ?
                        <>
                            <h3>Welcome Back...</h3>
                            <p>Please enter your email and password</p>
                        </>
                        :
                        <>
                            <h3>Welcome!</h3>
                            <p>Please register to access our filter function!</p>
                            {!isLoginPage && <p>Already have an account? <a onClick={() => {
                                setIsLoginPage(true);
                            }}>Login</a></p>}
                        </>
                    }
                </div>
                {isLoginPage ? <Login /> : <Register />}
                {isLoginPage && <p>Don't have an account? <a onClick={() => {
                    setIsLoginPage(false);
                }}>Sign up</a></p>}
            </div>
        </div>
    );
}

export default RegisterLoginPage;