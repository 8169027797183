import React from "react";
import "./FormInput.css";

export default function FormInput(props) {
    return (
        <label className={props.input.class ? `${props.input.class} form-txt` : "form-txt"}>
            <p>{props.input.label}</p>
            <img src={props.input.img} />
            <input type={props.input.type} value={props.input.value} onChange={(e) => { props.input.update(e.target.value); }}></input>
        </label >
    );
}