import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./app/store.js";
import { SetAccessToken } from './app/SetAccessToken';
import './index.css';

// console.log("1");
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store} >
    <SetAccessToken />
  </Provider>
);

// TODO: consider put getAccessToken() to context
// root.render(
//   <UserContext.Provider value={accessToken} >
//     <SalesContainer />
//   </UserContext.Provider>
// );

