import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDevelopments, getFavourites } from "../../api/developments";
import { selectAccessToken } from "../../util/accessTokenSlice";
import store from "../../app/store";

// INITIAL STATE
const initialState = {
    developments: [],
    status: "idle",
    error: null
};

// ASYNC THUNKS
export const asyncFetchDevelopments = createAsyncThunk("developments/fetchDevelopments", async () => {
    const temp = await getDevelopments();

    const developments = [];
    temp.forEach(i => {
        developments.push({
            ...i,
            "unique-id": "NV" + i.id.toString().padStart(4, '0'),
            "name-chinese": i.nameCN,
            "completion-array": i.completionArr,
            "six-bed": i.bed6,
            "five-bed": i.bed5,
            "four-bed": i.bed4,
            "three-bed": i.bed3,
            "two-bed": i.bed2,
            "one-bed": i.bed1,
            "studio": i.bed0,
            "in-map": i.inMap ? "t" : "",
            "in-svg": "t",
            "line-num": i.lineNum,
            "lowest-price": i.minPrice,
            "highest-price": i.maxPrice,
            "total-units": i.units,
            "unique-station-id": i.stationId,
            wechatid: null,
            wechatlink: null,
            "zone-main": i.zoneMain,
            "zone-other": i.zoneOther,
        });
    });



    // console.log("finished fetching developments");
    let favourites;
    // console.log(selectAccessToken(store.getState()));
    if (selectAccessToken(store.getState())) {
        favourites = await getFavourites();
    }
    // console.log("finished fetching favourites");
    if (developments) {
        for (let i = 0; i < developments.length; i++) {
            if (favourites && favourites.length !== 0) {
                for (let j = 0; j < favourites.length; j++) {
                    if (developments[i]["unique-id"] === favourites[j]["developmentid"]) {
                        developments[i]["favourite"] = true;
                        break;
                    } else {
                        developments[i]["favourite"] = false;
                    }
                }
            } else {
                developments[i]["favourite"] = false;
            }
        }
    }
    console.log(developments);
    return developments;
});


// SLICE OBJECT
export const developmentsSlice = createSlice({
    name: "developments",
    initialState,
    reducers: {
        setDevelopments: (state, action) => {
            state.developments = action.payload;
        },
        editFavInDevelopments: (state, action) => {
            const { id, bool } = action.payload;
            const dev = state.developments.find((d) => d['unique-id'] === id);
            if (dev) {
                dev.favourite = bool;
            }
        },
        setDevelopmentStatus: (state, action) => {
            state.status = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(asyncFetchDevelopments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(asyncFetchDevelopments.fulfilled, (state, action) => {
                // console.log("fulfilled");
                state.status = 'succeeded';
                state.developments = action.payload;
            })
            .addCase(asyncFetchDevelopments.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    }
});



// SELECTORS
export const selectAllDevelopments = (state) => state.developments.developments;

// EXPORTS
export const { setDevelopments, editFavInDevelopments, setDevelopmentStatus } = developmentsSlice.actions;
export default developmentsSlice.reducer;