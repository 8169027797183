import "./Insight.scss";
import React from "react";

const img = require("./3.jpeg");
const article = require("./article3.png");

export default function Insight(props) {
    const openURL = () => {
        window.open(props.insightInfo.url, "_blank");
    };
    return (
        <div className={props.format + " insight-container"}>
            <img src={props.insightInfo.img} />
            <div className="insight-text">
                <p className="insight-tag">{props.insightInfo.tag}</p>
                <h3 className="insight-title">{props.insightInfo.header}</h3>
                <p className="insight-desc">{props.insightInfo.content}</p>
                <button type="button" onClick={openURL}>READ</button>
            </div>
        </div>
    );
}