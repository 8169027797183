import "./Services.scss";
import React, { useEffect, useContext } from "react";
import { SalesContext } from "../../../context/SalesContext";

export default function Services(props) {
  const { lang, loadLang } = useContext(SalesContext);
  useEffect(() => {
    loadLang(lang);
  });
  return (
    <section className="services">
      <div className="services-title title">
        <p className="title-main text-xl">
          <span language="en">One Stop Shop</span>
          <span language="zh">一站式</span>
        </p>
        <p className="title-sub font-special">
          <span language="en">VIP Services</span>
          <span language="zh">VIP 服务</span>
        </p>
        <div className="deco-dash"></div>
      </div>
      <div className="services-list">
        {props.service.map((item, index) => {
          return (
            <div className="service-item" key={`service-${item}-${index}`}>
              <item.img />
              <p language="en">{item.en}</p>
              <p language="zh">{item.zh}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
}
