import { axiosCustom } from "./axoisCustom";
import { setAccessToken } from "../util/accessTokenSlice";
import store from '../app/store';

export const registerUser = async (title, name, email, password) => {
    try {
        const response = await axiosCustom.post("/users/register", {
            title: title,
            name: name,
            email: email,
            password: password
        });
        if (!response.data) return false;
        store.dispatch(setAccessToken(response.data['accessToken']));
        return true;
    } catch (err) {
        console.log(err);
    }
};

export const loginUser = async (email, password) => {
    try {
        const response = await axiosCustom.post(`/users/login`, {
            email: email,
            password: password
        });

        if (!response.data) return false;
        store.dispatch(setAccessToken(response.data['accessToken']));
        return true;
    } catch (err) {
        console.log(err);
    }
};
export const logoutUser = async () => {
    try {
        const response = await axiosCustom.post(`/users/logout`);
        if (!response.data) return false;
        store.dispatch(setAccessToken(""));
        return true;
    } catch (err) {
        console.log(err);
    }
}

