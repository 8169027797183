import "./App.css";
import React, { useEffect, useContext } from "react";
import { AppRoutes } from "./AppRoutes";
import { SalesContext } from "../context/SalesContext";

export const App = () => {
    const { loadLang, lang } = useContext(SalesContext);
    // useEffect(() => { loadLang(lang); });
    return <AppRoutes />;
};

