import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    "slider-price-index": [0, 100],
    "price": [0, 0],
    // "filter-price": [minPrice, maxPrice],
    "zone": {
        "zone-one": false,
        "zone-two": false,
        "zone-three": false,
        "zone-four": false,
        "zone-five": false,
        "zone-six": false,
    },
    "postcode": "",
    "borough": "",
    "bedrooms": {
        "studio": false,
        "one-bed": false,
        "two-bed": false,
        "three-bed": false,
        "four-bed": false,
        "five-bed": false,
    },
    "developer": {
        "berkeley": false,
        "barratt": false,
        "galliard": false,
    },
    "other-developer": "",
    "completion": {
        "completed": false,
        "yr2024": false,
        "yr2025": false,
        "yr2026": false,
        "yr2027": false,
    }
};
// SLICE OBJECT
export const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        updateSliderPriceIndex: (state, action) => { // updateFilterPrice([3, 80])
            state["slider-price-index"] = action.payload;
        },
        updatePrice: (state, action) => { // updateFilterPrice([295000, 12000000])
            state['price'] = action.payload;
        },
        updateZone: (state, action) => { // updateZone({zone: e.target.name, checked: e.target.checked})
            state["zone"][action.payload.name] = action.payload.checked;
        },
        updatePostcode: (state, action) => { // updatePostcode(e.target.value.toUpperCase())
            state["postcode"] = action.payload;
        },
        updateBorough: (state, action) => { // updateBorough(e.target.value.toUpperCase())
            state["borough"] = action.payload;
        },
        updateBedrooms: (state, action) => { // updateBedrooms({room: e.target.name, checked: e.target.checked})
            state["bedrooms"][action.payload.name] = action.payload.checked;
        },
        updateDeveloper: (state, action) => { // updateDeveloper({developer: e.target.name, checked: e.target.checked})
            state["developer"][action.payload.name] = action.payload.checked;
        },
        updateOtherDeveloper: (state, action) => { // updateOtherDeveloper(e.target.value.toUpperCase())
            state["other-developer"] = action.payload;
        },
        updateCompletion: (state, action) => { // updateCompletion({completion: e.target.name, checked: e.target.checked})
            state["completion"][action.payload.name] = action.payload.checked;
        },
        clearFilter: () => initialState
    }
});



// SELECTORS
export const selectFilterAll = (state) => state.filters;
export const selectFilterSliderPriceIndex = (state) => state.filters['slider-price-index'];
export const selectFilterPrice = (state) => state.filters['price'];
export const selectFilterZone = (state) => state.filters['zone'];
export const selectFilterPostcode = (state) => state.filters['postcode'];
export const selectFilterBorough = (state) => state.filters['borough'];
export const selectFilterBedrooms = (state) => state.filters['bedrooms'];
export const selectFilterDeveloper = (state) => state.filters['developer'];
export const selectFilterOtherDeveloper = (state) => state.filters['other-developer'];
export const selectFilterCompletion = (state) => state.filters['completion'];



// EXPORTS
export const {
    updateBedrooms,
    updateBorough,
    updateCompletion,
    updateDeveloper,
    updatePrice,
    updateOtherDeveloper,
    updatePostcode,
    updateSliderPriceIndex,
    updateZone,
    clearFilter
} = filtersSlice.actions;

export default filtersSlice.reducer;