import "./FilterView.scss";
import "./FilterViewMobile.scss";
import React, { useContext, useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Input, Button, Slider, Checkbox, FormGroup, FormControlLabel, TextField, InputAdornment, Switch } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
    clearFilter,
    updateBedrooms,
    updateBorough,
    updateCompletion,
    updateDeveloper,
    updatePrice,
    updateOtherDeveloper,
    updatePostcode,
    updateSliderPriceIndex,
    updateZone,
    selectFilterSliderPriceIndex,
    selectFilterPrice,
    selectFilterZone,
    selectFilterPostcode,
    selectFilterBorough,
    selectFilterBedrooms,
    selectFilterDeveloper,
    selectFilterOtherDeveloper,
    selectFilterCompletion,
} from "../../../features/filters/filtersSlice";
import {
    selectFavDevelopments
} from "../../../features/favourites/favouritesSlice";
import {
    selectAllDevelopments
} from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";

const ariaLabel = { 'aria-label': 'description' };
const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#0971f1',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            // main: 'rgba(250, 186, 142, 255)',
            contrastText: '#fff',
        },
    },
});

const valuetext = (value) => {
    return `£${value}`;
};

export const FilterView = (props) => {
    const dispatch = useDispatch();

    const filterSliderPriceIndex = useSelector(selectFilterSliderPriceIndex); // [20, 80]
    const filterPrice = useSelector(selectFilterPrice); // [295000, 1000000]
    const filterZone = useSelector(selectFilterZone); // {"zone-one": false, ...}
    const filterPostcode = useSelector(selectFilterPostcode); // ""
    const filterBorough = useSelector(selectFilterBorough); // ""
    const filterBedrooms = useSelector(selectFilterBedrooms); // {"studio": false, ...}
    const filterDeveloper = useSelector(selectFilterDeveloper); // {"berkeley": false, ...}
    const filterOtherDeveloper = useSelector(selectFilterOtherDeveloper); // ""
    const filterCompletion = useSelector(selectFilterCompletion); // {"completed": false, ...}

    const allFavourites = useSelector(selectFavDevelopments);
    const allDevelopments = useSelector(selectAllDevelopments);

    const { isWidthHideFilter, showFilter, setShowFilterStatus } = useContext(SalesContext);
    const [maxFilterHeight, setMaxFilterHeight] = useState("100vh");
    // ========== CONVERSION ========== //
    const convertNumToCurrency = (num) => {
        if (num) {
            return `£${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        }
    };
    const convertPriceToIndex = (num) => {
        return ((num - props.minMax[0]) * 100) / (props.minMax[1] - props.minMax[0]);
    };
    const convertIndexToPrice = (index) => {
        return Math.round((index * (props.minMax[1] - props.minMax[0]) / 100 + props.minMax[0]) / 1000) * 1000;
    };

    // ========== SET FILTERS ========== //
    // ----- price: text and slider ----- //
    const updateFilterPriceAndIndex = (e, index = 2) => {
        if (index === 2) { // the slider
            dispatch(updateSliderPriceIndex(e.target.value));
            dispatch(updatePrice(filterSliderPriceIndex.map((i) => convertIndexToPrice(i))));
        } else if (index === 1) { // the right one
            dispatch(updatePrice([filterPrice[0], Number(e.target.value)]));
            dispatch(updateSliderPriceIndex([filterSliderPriceIndex[0], convertPriceToIndex(Number(e.target.value))]));
        } else if (index === 0) { // the left one
            dispatch(updatePrice([Number(e.target.value), filterPrice[1]]));
            dispatch(updateSliderPriceIndex([convertPriceToIndex(Number(e.target.value)), filterSliderPriceIndex[1]]));
        }
    };
    // ----- location: zone checkbox ----- //
    const updateFilterZone = (e) => {
        dispatch(updateZone({
            name: [e.target.name],
            checked: e.target.checked
        }));
        // props.setOneFilter("zone", { ...props.filter.zone, [e.target.name]: e.target.checked });
    };
    // ----- location: postcode textfield ----- //
    const updateFilterPostcode = (e) => {
        dispatch(updatePostcode(e.target.value.toUpperCase()));
        // props.setOneFilter("postcode", e.target.value.toUpperCase());
    };
    // ----- location: borough textfield ----- //
    const updateFilterBorough = (e) => {
        dispatch(updateBorough(e.target.value.toUpperCase()));
        // props.setOneFilter("borough", e.target.value.toUpperCase());
    };
    // ----- property: bedrooms checkbox ----- //
    const updateFilterBedrooms = (e) => {
        dispatch(updateBedrooms({
            name: e.target.name,
            checked: e.target.checked,
        }));
        // props.setOneFilter("bedrooms", { ...props.filter.bedrooms, [e.target.name]: e.target.checked });
    };
    // ----- property: developer checkbox & textfield ----- //
    const updateFilterDeveloper = (e) => {
        dispatch(updateDeveloper({
            name: e.target.name,
            checked: e.target.checked,
        }));
        // props.setOneFilter("developer", { ...props.filter.developer, [e.target.name]: e.target.checked });
    };

    const updateFilterOtherDeveloper = (e) => {
        dispatch(updateOtherDeveloper(e.target.value.toUpperCase()));
        // props.setOneFilter("other-developer", e.target.value);
    };
    // ----- property: completion checkbox ------ //
    const updateFilterCompletion = (e) => {
        dispatch(updateCompletion({
            name: e.target.name,
            checked: e.target.checked,
        }));
        // props.setOneFilter("completion", { ...props.filter.completion, [e.target.name]: e.target.checked });
    };

    // ===== button: reset ===== //
    const backToDefault = () => {

        dispatch(clearFilter());
        props.setSelectHouses(false);
        const data = props.showFav ? allFavourites : allDevelopments;
        props.setPriceRange(data);
        props.renderWithSort(props.sort, data);
        props.renderSVG(data);
        if (isWidthHideFilter) {
            setShowFilterStatus(false);
        }
    };

    useEffect(() => {
        let maxFilterHeight = "100vh";
        if (isWidthHideFilter) {
            maxFilterHeight = "100vh";
        } else {
            if (props.grid) {
                const grid = document.querySelector(".grid-container");
                if (grid) {
                    const height = grid.clientHeight;
                    maxFilterHeight = height < window.innerHeight - 60 ? "100vh" : height + "px";
                }
            } else {
                const svg = document.querySelector(".svg-container");
                if (svg) {
                    const height = svg.clientHeight;
                    maxFilterHeight = height < window.innerHeight - 60 ? "calc(100vh + 210px)" : height + "px";
                }
                // maxFilterHeight = "calc((100vw - 350px) * 0.618)";
            }
        }
        setMaxFilterHeight(maxFilterHeight);
    }, [props.grid, isWidthHideFilter, window.innerWidth]);


    return (
        <div className={isWidthHideFilter ? (showFilter ? "filter-container unhide" : "filter-container hide") : "filter-container"}>
            <span className="material-symbols-outlined filter-container-cross" onClick={() => {
                setShowFilterStatus(false);
            }}>close</span>
            <div className={(props.stopFilterFixed ? "filter-view absolute" : "filter-view fixed")} style={{ maxHeight: maxFilterHeight }}>
                {/* <div className={(props.stopFilterFixed ? "filter-view absolute" : "filter-view fixed")} style={{ maxHeight: isWidthHideFilter ? "100vh" : (props.grid ? (document.querySelector(".grid-container").clientHeight < window.innerHeight - 60 ? `${document.querySelector(".grid-container").clientHeight}px` : "100vh") : "calc((100vw - 350px) * 0.618)") }}> */}
                <div id="reset-container">
                    <div className="filter-title"><span language="en">Filter</span><span language="zh">筛选</span></div>
                    <ThemeProvider theme={theme}>
                        <Button variant="outlined" color="neutral" onClick={() => {
                            if (isWidthHideFilter) {
                                setShowFilterStatus(false);
                            }
                            props.updateData();
                        }} style={{ whiteSpace: "nowrap", width: "auto" }}><span language="en">Update Filter</span><span language="zh">更新筛选</span></Button>
                        <Button color="neutral" onClick={backToDefault}><span language="en">Reset</span><span language="zh">重置</span></Button>
                    </ThemeProvider>
                </div>
                <div className="filter-category filter-price">
                    <p language="en">Price</p>
                    <p language="zh">价格</p>
                    {/* <div><b><span language="en">Current price:<br /></span><span language="zh">已选价格：</span></b> <span language="en">from </span><span language="zh">从 </span> {convertNumToCurrency(filterPrice[0])} <span language="en">to </span><span language="zh"> 至 </span> {convertNumToCurrency(filterPrice[1])}</div> */}
                    {/* <ThemeProvider theme={theme}>
                        <Slider
                            getAriaLabel={() => 'Price range'}
                            value={filterSliderPriceIndex}
                            onChange={(e) => { updateFilterPriceAndIndex(e); }}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            disableSwap
                            size="small"
                            marks={[
                                {
                                    value: 0,
                                    label: `${filterPrice[0] ? convertNumToCurrency(filterPrice[0]) : 0}`,
                                },
                                {
                                    value: 100,
                                    label: `${filterPrice[1] ? convertNumToCurrency(filterPrice[1]) : 100}`,
                                }
                            ]}
                            valueLabelFormat={convertIndexToPrice}
                            color="neutral"
                            step={0.1}
                        />
                    </ThemeProvider> */}
                    <div language="en" className="price-textfield">
                        <TextField
                            id="outlined-price-from"
                            label="From"
                            variant="outlined"
                            size="small"
                            value={filterPrice[0]}
                            InputProps={{
                                startAdornment: <InputAdornment position="start" disableTypography>£</InputAdornment>,
                            }}
                            onChange={(e) => { updateFilterPriceAndIndex(e, 0); }}
                        />
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}><svg t="1663166810322" className="icon" id="arrow" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7590" width="200" height="200"><path d="M558.933333 490.666667L384 665.6l59.733333 59.733333 234.666667-234.666666L443.733333 256 384 315.733333l174.933333 174.933334z" p-id="7591"></path></svg></div>
                        <TextField
                            id="outlined-price-to"
                            label="To"
                            variant="outlined"
                            size="small"
                            value={filterPrice[1]}
                            InputProps={{
                                // startAdornment: <InputAdornment position="start" disableTypography><img src={img} style={{ height: "10px" }} /></InputAdornment>,
                                startAdornment: <InputAdornment position="start" disableTypography>£</InputAdornment>,
                            }}
                            onChange={(e) => { updateFilterPriceAndIndex(e, 1); }}
                        />
                    </div>
                    <div language="zh" className="price-textfield">
                        <TextField
                            id="outlined-price-from"
                            label="从"
                            variant="outlined"
                            size="small"
                            value={filterPrice[0]}
                            InputProps={{
                                startAdornment: <InputAdornment position="start" disableTypography>£</InputAdornment>,
                            }}
                            onChange={(e) => { updateFilterPriceAndIndex(e, 0); }}
                        />
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}><svg t="1663166810322" className="icon" id="arrow" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7590" width="200" height="200"><path d="M558.933333 490.666667L384 665.6l59.733333 59.733333 234.666667-234.666666L443.733333 256 384 315.733333l174.933333 174.933334z" p-id="7591"></path></svg></div>
                        <TextField
                            id="outlined-price-to"
                            label="至"
                            variant="outlined"
                            size="small"
                            value={filterPrice[1]}
                            InputProps={{
                                startAdornment: <InputAdornment position="start" disableTypography>£</InputAdornment>,
                            }}
                            onChange={(e) => { updateFilterPriceAndIndex(e, 1); }}
                        />
                    </div>
                </div>
                <div className="filter-category filter-location">
                    <p language="en">Location</p>
                    <p language="zh">地理位置</p>
                    <div language="en" className="multiple-choice-layout">
                        <label >Zone</label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-one"]} name="zone-one" onChange={updateFilterZone} color="neutral" />} label="Zone 1" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-two"]} name="zone-two" onChange={updateFilterZone} color="neutral" />} label="Zone 2" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-three"]} name="zone-three" onChange={updateFilterZone} color="neutral" />} label="Zone 3" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-four"]} name="zone-four" onChange={updateFilterZone} color="neutral" />} label="Zone 4" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-five"]} name="zone-five" onChange={updateFilterZone} color="neutral" />} label="Zone 5" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-six"]} name="zone-six" onChange={updateFilterZone} color="neutral" />} label="Zone 6+" />
                            </ThemeProvider>
                        </FormGroup>
                    </div>
                    <div language="zh" className="multiple-choice-layout">
                        <label>地铁区</label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-one"]} name="zone-one" onChange={updateFilterZone} color="neutral" />} label="1区" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-two"]} name="zone-two" onChange={updateFilterZone} color="neutral" />} label="2区" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-three"]} name="zone-three" onChange={updateFilterZone} color="neutral" />} label="3区" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-four"]} name="zone-four" onChange={updateFilterZone} color="neutral" />} label="4区" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-five"]} name="zone-five" onChange={updateFilterZone} color="neutral" />} label="5区" />
                                <FormControlLabel control={<Checkbox checked={filterZone["zone-six"]} name="zone-six" onChange={updateFilterZone} color="neutral" />} label="6区+" />
                            </ThemeProvider>
                        </FormGroup>
                    </div>
                    <div className="filter-location-input-container">
                        <div className="filter-location-postcode horizontal-filter">
                            <label htmlFor="input-location-postcode"><span language="en">Postcode</span><span language="zh">邮编</span></label>
                            <Input
                                placeholder="W1T 4SG"
                                value={filterPostcode}
                                onChange={updateFilterPostcode}
                                inputProps={ariaLabel}
                            />
                        </div>
                        <div className="filter-location-borough horizontal-filter">
                            <label htmlFor="input-location-borough"><span language="en">Borough</span><span language="zh">区域</span></label>
                            <Input
                                placeholder="Camden"
                                inputProps={ariaLabel}
                                value={filterBorough}
                                onChange={updateFilterBorough}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter-category filter-property">
                    <p language="en">Property</p>
                    <p language="zh">房产信息</p>
                    <div language="en" className="multiple-choice-layout">
                        <label>Bedrooms</label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["studio"]} name="studio" onChange={updateFilterBedrooms} color="neutral" />} label="Studio" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["one-bed"]} name="one-bed" onChange={updateFilterBedrooms} color="neutral" />} label="1 bed" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["two-bed"]} name="two-bed" onChange={updateFilterBedrooms} color="neutral" />} label="2 bed" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["three-bed"]} name="three-bed" onChange={updateFilterBedrooms} color="neutral" />} label="3 bed" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["four-bed"]} name="four-bed" onChange={updateFilterBedrooms} color="neutral" />} label="4 bed" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["five-bed"]} name="five-bed" onChange={updateFilterBedrooms} color="neutral" />} label="5 bed" />
                            </ThemeProvider>
                        </FormGroup>
                    </div>
                    <div language="zh" className="multiple-choice-layout">
                        <label>户型</label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["studio"]} name="studio" onChange={updateFilterBedrooms} color="neutral" />} label="开间" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["one-bed"]} name="one-bed" onChange={updateFilterBedrooms} color="neutral" />} label="一室" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["two-bed"]} name="two-bed" onChange={updateFilterBedrooms} color="neutral" />} label="两室" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["three-bed"]} name="three-bed" onChange={updateFilterBedrooms} color="neutral" />} label="三室" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["four-bed"]} name="four-bed" onChange={updateFilterBedrooms} color="neutral" />} label="四室" />
                                <FormControlLabel control={<Checkbox checked={filterBedrooms["five-bed"]} name="five-bed" onChange={updateFilterBedrooms} color="neutral" />} label="五室" />
                            </ThemeProvider>
                        </FormGroup>
                    </div>
                    <div className="switch-layout">
                        <label language="zh" style={{ fontWeight: "600" }}>只显示别墅</label>
                        <label language="en" style={{ fontWeight: "600" }}>Houses Only</label>
                        <Switch
                            checked={props.selectHouses}
                            onChange={(e) => { props.setSelectHouses(e.target.checked); }}
                            color="secondary"
                        />
                    </div>
                    {/* <div className="filter-property-developer-container multiple-choice-vertical-layout">
                        <label><span language="en">Developer</span><span language="zh">开发商</span></label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <span language="en"><FormControlLabel control={<Checkbox checked={filterDeveloper["berkeley"]} name="berkeley" onChange={updateFilterDeveloper} color="neutral" />} label="Berkeley" /></span>
                                <span language="zh"><FormControlLabel control={<Checkbox checked={filterDeveloper["berkeley"]} name="berkeley" onChange={updateFilterDeveloper} color="neutral" />} label="伯克利" /></span>
                                <span language="en"><FormControlLabel control={<Checkbox checked={filterDeveloper["barratt"]} name="barratt" onChange={updateFilterDeveloper} color="neutral" />} label="Barratt" /></span>
                                <span language="zh"><FormControlLabel control={<Checkbox checked={filterDeveloper["barratt"]} name="barratt" onChange={updateFilterDeveloper} color="neutral" />} label="邦瑞" /></span>
                                <span><FormControlLabel control={<Checkbox checked={filterDeveloper["galliard"]} name="galliard" onChange={updateFilterDeveloper} color="neutral" />} label="Galliard" /></span>
                                <div id="other-developer-container">
                                    <div><span language="en">Other</span><span language="zh">其他</span></div>
                                    <Input
                                        inputProps={ariaLabel}
                                        value={filterOtherDeveloper}
                                        onChange={updateFilterOtherDeveloper}
                                    />
                                </div>
                            </ThemeProvider>
                        </FormGroup>
                    </div> */}

                    <div className="multiple-choice-layout filter-property-completion-container">
                        <label><span language="en">Est. Completion</span><span language="zh">预计交房时间</span></label>
                        <FormGroup id="filter-multiple-formgroup">
                            <ThemeProvider theme={theme}>
                                <span language="en">
                                    <FormControlLabel control={<Checkbox checked={filterCompletion["completed"]} name="completed" onChange={updateFilterCompletion} color="neutral" />} label="Completed" />
                                </span>
                                <span language="zh">
                                    <FormControlLabel control={<Checkbox checked={filterCompletion["completed"]} name="completed" onChange={updateFilterCompletion} color="neutral" />} label="现房" />
                                </span>
                                <FormControlLabel control={<Checkbox checked={filterCompletion["yr2024"]} name="yr2024" onChange={updateFilterCompletion} color="neutral" />} label="2024" />
                                <FormControlLabel control={<Checkbox checked={filterCompletion["yr2025"]} name="yr2025" onChange={updateFilterCompletion} color="neutral" />} label="2025" />
                                <FormControlLabel control={<Checkbox checked={filterCompletion["yr2026"]} name="yr2026" onChange={updateFilterCompletion} color="neutral" />} label="2026" />
                                <FormControlLabel control={<Checkbox checked={filterCompletion["yr2027"]} name="yr2027" onChange={updateFilterCompletion} color="neutral" />} label="2027+" />
                            </ThemeProvider>
                        </FormGroup>
                    </div>
                </div>
                <ThemeProvider theme={theme}>
                    <Button variant="outlined" color="neutral" onClick={() => {
                        if (isWidthHideFilter) {
                            setShowFilterStatus(false);
                        }
                        props.updateData();
                    }} style={{ whiteSpace: "nowrap", width: "auto" }}><span language="en">Update Filter</span><span language="zh">更新筛选</span></Button>
                    <Button color="neutral" onClick={backToDefault}><span language="en">Reset</span><span language="zh">重置</span></Button>
                </ThemeProvider>
            </div>
        </div>
    );

};

export default FilterView;
