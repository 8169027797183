import React from 'react';
import "./FormSelect.css";

export default function FormSelect(props) {
    // const [selectedValue, setSelectedValue] = useState('');

    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };

    return (
        <label className={props.input.class ? `${props.input.class} form-select` : "form-select"}>
            <p>{props.input.label}</p>
            {props.input.img && <img src={props.input.img} />}

            return (
            {/* <select value={selectedValue} onChange={handleChange}>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
            </select>
            ); */}
            <select value={props.input.selectedValue} onChange={(e) => { props.input.update(e.target.value); }}>
                {props.input.options.map(i => {
                    return (<option value={i.replace(" ", "")}>{i}</option>);
                })}
            </select>
            {/* <input type={props.input.type} value={props.input.value} onChange={(e) => { props.input.update(e.target.value); }}></input> */}
        </label >
    );
}