import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Header.scss";
import "./HeaderMobile.scss";
import { NavLink } from "react-router-dom";
import { SalesContext } from "../../context/SalesContext";
import { selectAccessToken } from "../../util/accessTokenSlice";
import { logoutUser } from "../../api/users";
import { setDevelopmentStatus } from "../../features/developments/developmentsSlice";
const nvreLogoWhite = require("../../assets/media/logo/company/nvreLogoWhite.png");
const nvreLogoPurple = require("../../assets/media/logo/company/nvreLogoPurple.png");

function Heading(props) {
  if (props.page === "tube") {
    return <p className="header-title">伦敦新开发楼盘汇总 2024年8月 </p>;
  } else {
    return <p></p>;
  }
}

function Header(props) {
  let lastScroll = 0;
  const [addBackground, setAddBackground] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showMenuDesktop, setShowMenuDesktop] = useState(true);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();
  const { lang, setLang, isMobile } = useContext(SalesContext);
  const [openMenu, setOpenMenu] = useState(false);

  const hideMobileHeader = () => {
    window.scrollTo(0, 0);
    if (isMobile && document.querySelector(".header-icon-openheader")) {
      document.querySelector(".header-icon-openheader").style.display = "block";
    }
    setShowMenuMobile(false);
    document.querySelector("html").style.overflow = "visible";
  };

  const toggleMenu = (bool) => {
    document.querySelector("html").style.overflow = bool ? "hidden" : "visible";
    setShowMenuMobile(bool);
  };

  useEffect(() => {
    if (props.page !== "tube" && props.page !== "login" && !isMobile) {
      let breakpoint = [window.innerHeight - 50, window.innerHeight - 0];
      const handleScroll = (event) => {
        const currentScroll = window.scrollY;
        if (currentScroll >= breakpoint[0]) {
          if (currentScroll > breakpoint[1]) {
            // SCROLL DOWN
            if (currentScroll > lastScroll) {
              setShowMenuDesktop(false);
              setAddBackground(false);
            }
            // SCROLL UP
            else if (currentScroll < lastScroll) {
              setShowMenuDesktop(true);
              setAddBackground(true);
            }
          }
        } else {
          setShowMenuDesktop(true);
          setAddBackground(false);
        }
        lastScroll = currentScroll;
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [props.page]);

  return (
    // <div className={isMobile ? "mobile" : ""}>
    <div
      className={
        isMobile
          ? showMenuMobile
            ? "mobile show-header header"
            : "mobile hide-header header"
          : showMenuDesktop
          ? addBackground
            ? "add-background header"
            : "header"
          : "header hide-header"
      }
      style={{ position: props.page === "tube" ? "fixed !important" : "auto" }}
    >
      {isMobile && !showMenuMobile && (
        <>
          <div
            className={
              props.page === "tube"
                ? "menu-icon menu-icon-open fixed"
                : "menu-icon menu-icon-open"
            }
            onClick={() => toggleMenu(true)}
          >
            <span className="material-symbols-outlined">menu</span>
          </div>
          <a className="nvre-logo-mobile" href="/">
            <img src={nvreLogoPurple} alt="nvre-logo" />
          </a>
        </>
      )}
      {/* <div className={isMobile ? (showMenuMobile ? "show-header header" : "hide-header header") : (showMenuDesktop ? (addBackground ? "add-background header" : "header") : ("header hide-header"))}> */}
      <div className={"header"}>
        {isMobile && (
          <div
            className="menu-icon menu-icon-close"
            onClick={() => toggleMenu(false)}
          >
            <span className="material-symbols-outlined">close</span>
          </div>
        )}
        <header>
          <nav
            className={
              props.darkBackground ? "dark-background" : "light-background"
            }
          >
            <a className="nvre-logo" href="/">
              <img
                src={
                  props.darkBackground || isMobile
                    ? nvreLogoWhite
                    : nvreLogoPurple
                }
                alt="nvre-logo"
              />
            </a>
            <Heading className="header-title" page={props.page} />
            <div className="nav-items">
              <NavLink
                className="navlink"
                to="/property"
                onClick={hideMobileHeader}
              >
                <p>{lang === "english" ? "For Sale" : "买房"}</p>
                {/* <p language="en">{lang === "english" ? "For Sale" : "买房"}</p> */}
                {/* <p language="zh">买房</p> */}
              </NavLink>
              <div
                className="relative"
                onMouseEnter={() => !isMobile && setOpenMenu(true)}
                onMouseLeave={() => !isMobile && setOpenMenu(false)}
              >
                <p className="navlink ">
                  {/* <span language="en" className="flex items-center gap-3">
                    <span>Landlords</span>
                    {isMobile && <span onClick={() => { setOpenMenu(!openMenu); }} class="material-symbols-outlined  font-light hover:cursor-pointer">{openMenu ? "expand_less" : "expand_more"}</span>}
                  </span> */}
                  <span className="flex items-center gap-3">
                    <span>{lang === "english" ? "Landlords" : "房东"}</span>
                    {isMobile && (
                      <span
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                        class="material-symbols-outlined  font-light hover:cursor-pointer"
                      >
                        {openMenu ? "expand_less" : "expand_more"}
                      </span>
                    )}
                  </span>
                </p>
                <div
                  className={`${
                    !isMobile ? "absolute top-[60px] left-[-0.5rem]" : ""
                  } px-2 ${openMenu ? "block" : "hidden"} ${
                    isMobile
                      ? "bg-transparent"
                      : props.darkBackground
                      ? "bg-main"
                      : "bg-white"
                  }`}
                >
                  <NavLink
                    className="navlink"
                    to="/services/stamp-duty-calculator"
                    onClick={hideMobileHeader}
                  >
                    <p>
                      {lang === "english"
                        ? "Stamp Duty Calculator"
                        : "印花税计算器"}
                    </p>
                    {/* <p language="en">{lang === "english" ? "Stamp Duty Calculator" : "印花税计算器"}</p> */}
                    {/* <p language="zh">印花税计算器</p> */}
                  </NavLink>
                  <NavLink
                    className="navlink"
                    to="/services/rental-yield"
                    onClick={hideMobileHeader}
                  >
                    <p>{lang === "english" ? "Rental Yield" : "租金收益率"}</p>
                    {/* <p language="en">{lang === "english" ? "Rental Yield" : "租金收益率"}</p> */}
                    {/* <p language="zh">租金收益率</p> */}
                  </NavLink>
                  <NavLink
                    className="navlink"
                    to="/services/rent-estimate"
                    onClick={hideMobileHeader}
                  >
                    <p>
                      {lang === "english" ? "Rent Estimator" : "租金估算器"}
                    </p>
                    {/* <p language="en">{lang === "english" ? "Rent Estimator" : "租金估算器"}</p> */}
                    {/* <p language="zh">租金估算器</p> */}
                  </NavLink>
                  <NavLink
                    className="navlink"
                    to="/services/mortgage-calculator"
                    onClick={hideMobileHeader}
                  >
                    <p>
                      {lang === "english"
                        ? "Mortgage Calculator"
                        : "贷款计算器"}
                    </p>
                  </NavLink>
                </div>
              </div>
              <NavLink className="navlink" to="/let" onClick={hideMobileHeader}>
                <p>{lang === "english" ? "Let" : "房东租赁"}</p>
                {/* <p language="en">{lang === "english" ? "Let" : "房东租赁"}</p> */}
                {/* <p language="zh">房东租赁</p> */}
              </NavLink>
              <NavLink
                className="navlink"
                to="/about"
                onClick={hideMobileHeader}
              >
                <p>{lang === "english" ? "About Us" : "关于我们"}</p>
                {/* <p language="en">{lang === "english" ? "About Us" : "关于我们"}</p> */}
                {/* <p language="zh">关于我们</p> */}
              </NavLink>
              {/* {accessToken ? (
                <NavLink
                  className="navlink"
                  to="/login"
                  onClick={async () => {
                    await logoutUser();
                    dispatch(setDevelopmentStatus("idle"));
                    hideMobileHeader();
                    // setRefresh(!refresh);
                  }}
                >
                  <p language="en">Logout</p>
                  <p language="zh">登出</p>

                </NavLink>
              ) : (
                <NavLink className="navlink" to="/login" onClick={hideMobileHeader}>

                  <p language="en">Login</p>
                  <p language="zh">登陆</p>
                </NavLink>
              )} */}
              <a
                className="nav-lang"
                onClick={() => {
                  if (lang === "english") {
                    // localStorage.setItem('language', "chinese");
                    setLang("chinese");
                    // document.querySelectorAll('[language=en]').forEach(i => i.classList.add('hideLanguage'));
                    // document.querySelectorAll('[language=zh]').forEach(i => i.classList.remove('hideLanguage'));
                    hideMobileHeader();
                  } else {
                    // localStorage.setItem('language', "english");
                    setLang("english");
                    // document.querySelectorAll('[language=zh]').forEach(i => i.classList.add('hideLanguage'));
                    // document.querySelectorAll('[language=en]').forEach(i => i.classList.remove('hideLanguage'));
                    hideMobileHeader();
                  }
                }}
              >
                {lang === "english" ? "中文" : "English"}
              </a>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
}

export default Header;
