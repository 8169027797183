import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Input, Button, Slider, Checkbox, FormGroup, FormControlLabel, TextField, InputAdornment } from "@mui/material";
import { SalesContext } from "../../../context/SalesContext";
import { registerUser } from "../../../api/users";
import FormInput from "../../../components/Form/FormInput/FormInput";
import FormCheckbox from "../../../components/Form/FormRadio/FormCheckbox";
import FormSelect from "../../../components/Form/FormSelect/FormSelect";

const emailIcon = require("../../../assets/media/icons/email1.png");
const lockIcon = require("../../../assets/media/icons/lock1.png");
const titleIcon = require("../../../assets/media/icons/name7.png");
const nameIcon = require("../../../assets/media/icons/name6.png");
const sourceIcon = require("../../../assets/media/icons/source.png");
const salesIcon = require("../../../assets/media/icons/people.png");


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        neutral: {
            main: 'rgba(250, 186, 142, 255)',
            contrastText: '#fff',
            borderColor: 'rgba(250, 186, 142, 255)'
        },
    },
});

export const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [loginRemember, setLoginRemember] = useState(false);
    const [sourceValue, setSourceValue] = useState('');
    const [sales, setSales] = useState('');

    const [passwordRepeat, setPasswordRepeat] = useState('');
    const { refresh, setRefresh } = useContext(SalesContext);
    let navigate = useNavigate();

    return (
        <div className="register-container">
            <form className="form-register" id="form-register" action="/register" method="POST">
                <div className="flex-row">
                    <FormInput input={{ class: "static", label: "Title *", type: "txt", value: title, img: titleIcon, update: (value) => { setTitle(value); } }} />
                    <FormInput input={{ class: "static", label: "Full Name *", type: "txt", value: name, img: nameIcon, update: (value) => { setName(value); } }} />
                </div>
                <FormInput input={{ class: "static", label: "Email *", type: "txt", value: email, img: emailIcon, update: (value) => { setEmail(value); } }} />
                <FormInput input={{ class: "static", label: "Password *", type: "password", value: password, img: lockIcon, update: (value) => { setPassword(value); } }} />
                <FormInput input={{ class: "static", label: "Repeat Password *", type: "password", value: passwordRepeat, img: lockIcon, update: (value) => { setPasswordRepeat(value); } }} />
                <FormCheckbox input={{ class: "", label: "Remember me", type: "checkbox", value: loginRemember, update: (value) => { setLoginRemember(value); } }} />
                <div className="flex-row">
                    <FormSelect input={{ class: "", label: "Source", options: ["please choose one", "wechat", "xiaohongshu", "friends"], selectedValue: sourceValue, img: sourceIcon, update: (value) => { setSourceValue(value); } }} />
                    <FormInput input={{ class: "static", label: "Sales", type: "txt", value: sales, img: salesIcon, update: (value) => { setSales(value); } }} />
                </div>
                {/* <div language="en" className="password-requirement">
                    Password must include:  <br />
                    <span>✓</span> A minimum of 8 characters <br />
                    <span>✓</span> 1 upper case <br />
                    <span>✓</span> 1 lower case <br />
                    <span>✓</span> 1 number or special character <br />
                </div>
                <div language="zh" className="password-requirement">
                    密码需包括：<br />
                    <span>✓</span> 最少8个字符<br />
                    <span>✓</span> 一个大写<br />
                    <span>✓</span> 一个小写<br />
                    <span>✓</span> 一个数字或者特殊字符<br />
                </div> */}
                <ThemeProvider theme={theme}>
                    <Button id="register-button" variant="outlined" color="neutral" onClick={async e => {
                        e.preventDefault();
                        if (!email || !password || !title || !name) {
                            return;
                        }
                        const success = await registerUser(title, name, email, password);
                        if (success) {
                            setEmail("");
                            setPassword("");
                            setTitle("");
                            setName("");
                            setRefresh(!refresh);
                            navigate("/");
                        } else {
                            console.log("register failed");
                        }
                    }}><span language="en">Register</span><span language="zh">注册</span></Button>
                </ThemeProvider>
                <p className="terms-and-conditions">By registering you are argreeing to the <a>terms and conditions</a></p>
            </form>
        </div>
    );
};