import axios from "axios";
import { selectAccessToken } from "../util/accessTokenSlice";
import store from "../app/store";


// const baseUrl = process.env.NODE_ENV === "production" ? "/api/v1" : "http://localhost:4000/api/v1";

// const baseUrl = process.env.NODE_ENV === "production" ? "https://us-central1-nvre-server.cloudfunctions.net/expressApi/api/v1" : "http://localhost:4000/api/v1";
const baseUrl = process.env.NODE_ENV === "https://us-central1-nvre-server.cloudfunctions.net/expressApi/api/v1";
// const baseUrl = "https://154.8.139.226:4000/api/v1";
// const baseUrl = process.env.NODE_ENV === "production"? "http://154.8.139.226:4000/api/v1": "http://localhost:4000/api/v1";
export const axiosCustom = axios.create({
    baseURL: baseUrl,
    headers: {
        "authorization": selectAccessToken(store.getState()) ? `bearer ${selectAccessToken(store.getState())}` : ""
    },
    withCredentials: true
});

// export default axiosCustom;