import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./YieldPage.module.scss";
import { generatePDFDocumentFromRef } from "../../../util/generatePDF";
import Spinner from "../../../components/Spinner/Spinner";
import { Helmet } from "react-helmet";
import { SalesContext } from "../../../context/SalesContext";
// import LanguageToggle from "../../../components/LanguageToggle/LanguageToggle";

export default function RentalYieldPage() {
  const [inputs, setInputs] = useState({
    propertyPrice: "",
    stampDuty: "0",
    otherPurchasingCosts: "0",
    rent: "",
    otherAdminCosts: "",
    growth: "5",
  });
  const tableRef = useRef(null);

  const [calculated, setCalculated] = useState({
    totalPurchasingPrice: 0,
    monthlyIncome: 0,
    annualRent: 0, // 年租金
    annualNetCashFlowReturn: 0, // 年净现金流回报
    annualPropertyAppreciationReturn: 0, // 每年房产增值
    annualTotalInvestmentReturn: 0, // 年投资回报总计
    annualRentToSaleRatio: 0, // 年租售比
    annualInvestmentReturnRate: 0, // 年投资回报率
    annualComprehensiveInvestmentReturnRate: 0, // 年综合投资回报率
  });

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { isSmallScreen, lang, loadLang } = useContext(SalesContext);

  const language = new URLSearchParams(window.location.search).get("language");

  useEffect(() => {
    if (language) {
      if (language === "chinese") {
        loadLang("chinese");
      } else if (language === "english") {
        loadLang("english");
      }
    }
  }, [language]);

  useEffect(() => {
    // console.log("fwea");
    // console.log(lang);
    let l;
    l = language || lang;
    console.log(l);

    const elementsToHide =
      l === "english"
        ? document.querySelectorAll("[language=zh]")
        : document.querySelectorAll("[language=en]");
    const elementsToShow =
      l === "english"
        ? document.querySelectorAll("[language=en]")
        : document.querySelectorAll("[language=zh]");
    // document.querySelectorAll('[language=en]').forEach(i => i.classList.add('hideLanguage'));
    // document.querySelectorAll('[language=zh]').forEach(i => i.classList.remove('hideLanguage'));

    elementsToHide.forEach((element) => {
      element.classList.add("hideLanguage");
      element.classList.remove("showLanguage");

      // element.style.display = 'none';
    });
    elementsToShow.forEach((element) => {
      element.classList.remove("hideLanguage");
      element.classList.add("showLanguage");
      // element.style.display = 'block';
    });
  }, [lang, language]);

  function handleSavethisTable() {}

  // function handleDownloadPDF() {
  //     const font = 'data:font/ttf;base64,';

  //     if (tableRef.current) {
  //         const pdf = new jsPDF();
  //         pdf.addFileToVFS('customFont.ttf', font);
  //         pdf.addFont('customFont.ttf', 'customFont', 'normal');
  //         autoTable(pdf, {
  //             html: tableRef.current,
  //             styles: { font: 'customFont', fillColor: [255, 255, 255], textColor: [0, 0, 0], font: 'helvetica', fontStyle: 'normal' },
  //             didParseCell: function (data) {
  //                 // Remove unwanted characters
  //                 data.cell.text = data.cell.text.map(text => text.replace(/[^\x20-\x7E]/g, ''));
  //             }
  //         });
  //         pdf.save('table.pdf');
  //     }
  // }

  function handleDownloadPDF() {
    setIsGeneratingPDF(true);
  }

  useEffect(() => {
    (async () => {
      if (isGeneratingPDF && tableRef.current) {
        const doc = await generatePDFDocumentFromRef(tableRef.current);
        if (doc) {
          // if (isSmallScreen) {
          //     const pdfBlob = doc.output('blob');
          //     const pdfFileName = 'desired-filename.pdf'; // Define your desired filename here
          //     const pdfUrl = URL.createObjectURL(pdfBlob);
          //     setPdfUrl(pdfUrl);
          //     const link = document.createElement('a');
          //     link.href = pdfUrl;
          //     link.download = pdfFileName; // Set the filename for the download
          //     document.body.appendChild(link); // Append to the body
          //     link.click(); // Programmatically click the link to trigger the download

          // } else {
          doc.save(`Rental Yield Table.pdf`);
          // }
          // doc.save(`test.pdf`);

          // !isSmallScreen && window.open(pdfUrl, '_blank');

          setIsGeneratingPDF(false);
        }
      }
    })();
  }, [isGeneratingPDF, tableRef.current]);
  // html2canvas(tableRef.current, { scale: 4, windowHeight: document.body.scrollHeight }).then((canvas) => {
  //     console.log(0);
  //     const imageData = canvas.toDataURL();
  //     console.log(1);
  //     const doc = new jsPDF({
  //         orientation: 'p',
  //         unit: 'mm',
  //         format: 'a4',
  //         compress: false
  //     });

  //     console.log(2);
  //     const imgWidth = doc.internal.pageSize.getWidth() * 1;
  //     const imgHeight = canvas.height * imgWidth / canvas.width;
  //     console.log(3);

  //     doc.addImage(imageData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, "FAST");
  //     doc.save(`test.pdf`);
  //     console.log(4);
  //     setIsGeneratingPDF(false);
  // });

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      totalPurchasingPrice,
    }));
  }, [inputs.propertyPrice, inputs.stampDuty, inputs.otherPurchasingCosts]);

  useEffect(() => {
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      monthlyIncome,
      annualNetCashFlowReturn,
    }));
  }, [inputs.rent, inputs.otherAdminCosts]);

  useEffect(() => {
    const annualRent = parseFloat(inputs.rent) * 12;
    setCalculated((prevCalculated) => ({ ...prevCalculated, annualRent }));
  }, [inputs.rent]);

  useEffect(() => {
    const propertyPrice = parseFloat(inputs.propertyPrice);
    const annualPropertyAppreciationReturn =
      (propertyPrice * parseFloat(inputs.growth)) / 100;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualPropertyAppreciationReturn,
    }));
  }, [inputs.propertyPrice, inputs.growth]);

  useEffect(() => {
    const totalPurchasingPrice = parseFloat(inputs.propertyPrice);
    //   parseFloat(inputs.propertyPrice) +
    //   parseFloat(inputs.stampDuty) +
    //   parseFloat(inputs.otherPurchasingCosts);
    const annualRent = parseFloat(inputs.rent) * 12;
    const annualRentToSaleRatio = annualRent / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualRentToSaleRatio,
    }));
  }, [
    inputs.propertyPrice,
    inputs.rent,
    inputs.stampDuty,
    inputs.otherPurchasingCosts,
  ]);

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    const annualInvestmentReturnRate =
      annualNetCashFlowReturn / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualInvestmentReturnRate,
    }));
  }, [
    inputs.propertyPrice,
    inputs.stampDuty,
    inputs.otherPurchasingCosts,
    inputs.rent,
    inputs.otherAdminCosts,
  ]);

  useEffect(() => {
    const totalPurchasingPrice =
      parseFloat(inputs.propertyPrice) +
      parseFloat(inputs.stampDuty) +
      parseFloat(inputs.otherPurchasingCosts);
    const monthlyIncome =
      parseFloat(inputs.rent) - parseFloat(inputs.otherAdminCosts);
    const annualNetCashFlowReturn = monthlyIncome * 12;
    const propertyPrice = parseFloat(inputs.propertyPrice);
    const annualPropertyAppreciationReturn =
      (propertyPrice * parseFloat(inputs.growth)) / 100;
    const annualTotalInvestmentReturn =
      annualNetCashFlowReturn + annualPropertyAppreciationReturn;
    const annualComprehensiveInvestmentReturnRate =
      annualTotalInvestmentReturn / totalPurchasingPrice;
    setCalculated((prevCalculated) => ({
      ...prevCalculated,
      annualTotalInvestmentReturn,
      annualComprehensiveInvestmentReturnRate,
    }));
  }, [
    inputs.propertyPrice,
    inputs.stampDuty,
    inputs.otherPurchasingCosts,
    inputs.rent,
    inputs.otherAdminCosts,
    inputs.growth,
  ]);

  useEffect(() => {
    const elementsToHide =
      lang === "english"
        ? document.querySelectorAll("[language=zh]")
        : document.querySelectorAll("[language=en]");
    const elementsToShow =
      lang === "english"
        ? document.querySelectorAll("[language=en]")
        : document.querySelectorAll("[language=zh]");
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });
    elementsToShow.forEach((element) => {
      element.style.display = "block";
    });
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>Rental Yield | Letting Engine</title>
        <meta
          name="description"
          content="Use our rental yield to calculate your return on investment. "
        />
        <meta
          name="keywords"
          content="residential property, real estate, london, rental yield, return on investment"
        />
      </Helmet>
      {/* <LanguageToggle language={isEnglish} setLanguage={setIsEnglish} /> */}
      <div
        className={`content-inner flex flex-col items-center gap-12 mx-auto ${styles.rentalYieldContainer}`}
      >
        {/* <div className={`content-inner flex flex-col items-center gap-12 mx-auto ${styles.rentalYieldContainer} ${isEnglish ? " isEnglish" : "isChinese"}`}> */}
        <h1 className="mx-3 text-center sm:text-left sm:mx-12 font-semibold mb-8 text-4xl ">
          <span language="en">Rental Yield Table</span>
          <span language="zh">房产投资租售比计算表</span>
        </h1>
        <div
          ref={tableRef}
          className={`${styles.tableContainer} to-print-rental-yield mx-3 sm:mx-12`}
        >
          <table
            className={`font-sans`}
            onChange={() => {
              setPdfUrl("");
            }}
          >
            <thead>
              <tr className="bg-main text-white">
                <th colSpan={2}>
                  <span language="en">
                    Property Investment Rent-to-Price Ratio Table
                  </span>
                  <span language="zh">房产投资租售比计算表</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-main border-t border-b border-main">
                <th colSpan={2}>
                  <span language="en">Total Purchase Costs</span>
                  <span language="zh">购房总费用</span>
                </th>
              </tr>
              <tr>
                <td>
                  <span language="en">Property Purchase Price</span>
                  <span language="zh">房产购买价格</span>
                </td>
                <td>
                  £
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.propertyPrice}
                    attribute="propertyPrice"
                    setInputs={setInputs}
                    isGeneratingPDF={isGeneratingPDF}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span language="en">Stamp Duty [Optional]</span>
                  <span language="zh">房产购买印花税 [选填]</span>
                </td>
                <td>
                  £
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.stampDuty}
                    attribute="stampDuty"
                    setInputs={setInputs}
                    isGeneratingPDF={isGeneratingPDF}
                    canIgnore
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span language="en">
                    Other Costs (e.g., legal fees, furniture costs) [Optional]
                  </span>
                  <span language="zh">
                    其他费用（如律师费，家具费等）[选填]
                  </span>
                </td>
                <td>
                  £
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.otherPurchasingCosts}
                    attribute="otherPurchasingCosts"
                    setInputs={setInputs}
                    isGeneratingPDF={isGeneratingPDF}
                    canIgnore
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td>
                  <span language="en">Total Purchase Costs</span>
                  <span language="zh">购房总费用</span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.totalPurchasingPrice}
                  />
                </td>
              </tr>

              <tr className="text-main border-t border-b border-main">
                <th colSpan={2}>
                  <span language="en">Monthly Net Cash Flow Analysis</span>
                  <span language="zh">月净现金流分析</span>
                </th>
              </tr>
              <tr>
                <td>
                  <span language="en">Monthly Rent</span>
                  <span language="zh">月租金</span>
                </td>
                <td>
                  £
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.rent}
                    attribute="rent"
                    setInputs={setInputs}
                    isGeneratingPDF={isGeneratingPDF}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <span language="en">
                    Monthly Expenses (e.g., service charges, rental management
                    fees)
                  </span>
                  <span language="zh">月支出额（如物业费，租赁管理费等）</span>
                </td>
                <td>
                  £
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.otherAdminCosts}
                    attribute="otherAdminCosts"
                    setInputs={setInputs}
                    isGeneratingPDF={isGeneratingPDF}
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td>
                  <span language="en">
                    Monthly Net Cash Flow (Rental Income - Monthly Expenses)
                  </span>
                  <span language="zh">月净现金流（租金收入-月支出额）</span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.monthlyIncome}
                  />
                </td>
              </tr>

              <tr className="text-main border-t border-b border-main">
                <th colSpan={2}>
                  <span language="en">Annual Investment Return Analysis</span>
                  <span language="zh">年投资回报分析</span>
                </th>
              </tr>
              <tr className="font-semibold">
                <td className="flex flex-col gap-1">
                  <span language="en">Annual Rent</span>{" "}
                  <span className={styles.span}>
                    <span language="en">(Monthly Rent X 12)</span>
                  </span>
                  <span language="zh">年租金 (月租金X12)</span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualRent}
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex flex-col gap-1">
                  <span language="en">Annual Net Cash Flow Return</span>{" "}
                  <span className={styles.span}>
                    <span language="en">(Monthly Net Cash Flow X 12)</span>
                  </span>
                  <span language="zh">年净现金流回报 (月净现金流X12)</span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualNetCashFlowReturn}
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex gap-1">
                  <span language="en">Annual Property Appreciation</span>{" "}
                  <span language="zh">每年房产增值</span>
                  <FormInput
                    lang={lang === "english"}
                    inputValue={inputs.growth}
                    setInputs={setInputs}
                    attribute="growth"
                    isGeneratingPDF={isGeneratingPDF}
                    special
                    width
                  />
                  %
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualPropertyAppreciationReturn}
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex flex-col gap-1">
                  <span language="en">Total Annual Investment Return</span>
                  <span className={styles.span}>
                    <span language="en">
                      [Annual Net Cash Flow Return + Annual Property
                      Appreciation]
                    </span>
                  </span>
                  <span language="zh">
                    年投资回报总计[年净现金流回报+年房产增值]
                  </span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualTotalInvestmentReturn}
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex flex-col gap-1">
                  <span language="en">Annual Rent-to-Price Ratio</span>{" "}
                  <span className={styles.span}>
                    <span language="en">
                      [Annual Rent / Property Purchase Price]
                    </span>
                  </span>
                  <span language="zh">年租售比 [年租金/房产购买价格]</span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualRentToSaleRatio}
                    percentage
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex  flex-col gap-1">
                  <span language="en">Annual Investment Return Rate</span>{" "}
                  <span className={styles.span}>
                    <span language="en">
                      [Annual Net Cash Flow Return / Total Purchase Costs]
                    </span>
                  </span>
                  <span language="zh">
                    年投资回报率 [年净现金流回报/购房总费用]
                  </span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualInvestmentReturnRate}
                    percentage
                  />
                </td>
              </tr>
              <tr className="font-semibold">
                <td className="flex  flex-col gap-1">
                  <span language="en">
                    Annual Comprehensive Investment Return Rate
                  </span>{" "}
                  <span className={styles.span}>
                    <span language="en">
                      [Total Annual Investment Return / Total Purchase Costs]
                    </span>
                  </span>
                  <span language="zh">
                    年综合投资回报率 [年投资回报总计/购房总费用]
                  </span>
                </td>
                <td>
                  <CalculatedValue
                    lang={lang === "english"}
                    value={calculated.annualComprehensiveInvestmentReturnRate}
                    percentage
                  />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr className="opacity-50">
                <td colSpan={2} className="text-gray text-xs">
                  {lang === "english"
                    ? "The amounts in the table are for reference only and are subject to actual occurrence;"
                    : "表中的金额以实际发生为准，以上数据仅供参考；"}
                </td>
              </tr>
              <tr className="opacity-50">
                <td colSpan={2} className="text-xs">
                  {lang === "english"
                    ? "If you reside continuously for 183 days after coming to the UK, you may be eligible for a 2% tax refund for overseas buyers with a visa that allows continuous residence."
                    : "来英后连续居住183天可以退2%海外买家税，但需要购房者有可以连续居住的签证。"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="flex gap-5 w-auto">
          <button className="btn  text-gray-700" onClick={handleDownloadPDF}>
            {isGeneratingPDF ? (
              <span>
                {lang === "english" ? "Downloading PDF..." : "PDF 下载中..."}
              </span>
            ) : (
              <p>
                <span>{lang === "english" ? "Download PDF" : "下载 PDF"}</span>
              </p>
            )}
          </button>
        </div>

        {isSmallScreen && pdfUrl && (
          <div className="pdf-download-link">
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              <span language="en">Open PDF</span>
              <span language="zh">打开 PDF</span>
            </a>
          </div>
        )}
      </div>
    </>
  );
}

function FormInput({
  inputValue,
  setInputs,
  attribute,
  isGeneratingPDF,
  special = false,
  width = false,
  canIgnore = false,
  lang,
}) {
  const [inputValueDisplay, setInputValueDisplay] = useState(inputValue);

  useEffect(() => {
    const result = parseFloat(inputValue).toLocaleString();
    setInputValueDisplay(result === "NaN" ? "" : result);
  }, [inputValue]);

  return (
    <>
      {isGeneratingPDF ? (
        <span>{inputValueDisplay}</span>
      ) : (
        <input
          className={width ? "w-5" : "w-24"}
          value={inputValueDisplay}
          placeholder={lang ? " Enter value" : " 请输入数值"}
          type="text"
          onChange={(e) => {
            const value = e.target.value.replace(/[^\d.]/g, "");
            if (value === "" || !isNaN(parseFloat(value))) {
              setInputs((prevInputs) => ({
                ...prevInputs,
                [attribute]: value,
              }));
            }
          }}
        />
      )}
      {!special && (
        <button
          className="underline underline-offset-4 text-xs text-main"
          style={{ display: isGeneratingPDF ? "none" : "inline-block" }}
          onClick={() => {
            setInputs((prevInputs) => ({
              ...prevInputs,
              [attribute]: canIgnore ? 0 : "",
            }));
          }}
        >
          {lang ? "clear" : "清除"}
        </button>
      )}
    </>
  );
}

function CalculatedValue({ value, percentage = false, lang }) {
  return (
    <p className="text-base font-sans ">
      {!isNaN(value) ? (
        <span className="">
          {percentage
            ? (value * 100).toFixed(1) + "%"
            : "£" + value.toLocaleString()}
        </span>
      ) : (
        <span className="opacity-50 text-sm font-light">
          {lang ? "Please complete all fields" : "（请完成表中信息）"}
        </span>
      )}
    </p>
    // <p className="opacity-50 text-base font-sans font-light">{!isNaN(value) ? (percentage ? (value * 100).toFixed(1) + "%" : "£" + value.toLocaleString()) : "（请完成表中信息）"}</p>
  );
}
