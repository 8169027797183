import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SalesContainer } from '../context/SalesContext';
import { asyncFetchAccessToken, selectAccessToken } from '../util/accessTokenSlice';

export const SetAccessToken = () => {
    const dispatch = useDispatch();
    const accessTokenStatus = useSelector((state) => state.accessToken.status);
    const token = useSelector(selectAccessToken);
    useEffect(() => {
        if (accessTokenStatus === "idle") {
            dispatch(asyncFetchAccessToken(token));
        }
    });

    return (
        <SalesContainer />
    );
};