import React, { useState, useContext, useEffect, createContext } from "react";
import { App } from "../app/App";
// import { App } from "../components/App/App"

export const SalesContext = createContext();

export const SalesContainer = () => {

    const [dataDevFavAll, setDataDevFavAll] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [dataDevFav, setDataDevFav] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [lang, setLang] = useState(new URLSearchParams(window.location.search).get('language') || "english");
    const [showHeaderBackground, setShowHeaderBackground] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isWidthHideFilter, setIsWidthHideFilter] = useState(false);
    const loadLang = (lang) => {
        console.log(lang);
        if (!lang) return;
        if (lang === "chinese") {
            console.log("!");
            setLang("chinese");
            document.querySelectorAll('[language=en]').forEach(i => {
                i.classList.add('hideLanguage');
                i.classList.remove('showLanguage');
            });
            document.querySelectorAll('[language=zh]').forEach(i => {
                i.classList.add("showLanguage");
                i.classList.remove('hideLanguage');
            });
        } else if (lang === "english") {
            console.log("?");
            setLang("english");
            document.querySelectorAll('[language=zh]').forEach(i => {
                i.classList.add('hideLanguage');
                i.classList.remove('showLanguage');
            }
            );
            document.querySelectorAll('[language=en]').forEach(i => {
                i.classList.add("showLanguage");
                i.classList.remove('hideLanguage');
            }
            );
        };
    };
    const [showFilter, setShowFilter] = useState(false);
    const setShowFilterStatus = (bool) => {
        if (bool) {
            setShowFilter(true);
            document.querySelector("html").style.overflow = "hidden";
            if (isMobile) {
                document.querySelector("thead").style.display = "none";
                // const tbody = document.querySelector("tbody");
                // tbody.style.zIndex = "100000000";
            }
        } else {
            setShowFilter(false);
            document.querySelector("html").style.overflow = "visible";
            if (isMobile) {
                document.querySelector("thead").style.display = "block";
                // document.querySelector("tbody").style.zIndex = "1000000";
            }
        }
    };

    useEffect(() => {
        if (lang.trim()) {
            loadLang(lang);
        }
    }, [lang]);

    // useEffect(() => {
    //     console.log("fewila");
    //     if (localStorage.getItem("language")) {
    //         setLang(localStorage.getItem("language"));
    //     } else {
    //         localStorage.setItem('language', lang);
    //     }
    // }, []);


    useEffect(() => {
        setIsMobile(window.innerWidth < 650);
        setIsWidthHideFilter(window.innerWidth < 920);
    }, []);

    useEffect(() => {
        let resizeTimer;

        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                setIsMobile(window.innerWidth < 650);
                setIsWidthHideFilter(window.innerWidth < 920);
            }, 300); // Adjust the delay time as needed
        };

        // setIsMobile(window.innerWidth < 650);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(resizeTimer);
        };
    }, [window]);

    return (
        <SalesContext.Provider value={{
            dataDevFavAll, setDataDevFavAll,
            favourites, setFavourites,
            dataDevFav, setDataDevFav,
            refresh, setRefresh,
            lang, setLang,
            loadLang,
            showHeaderBackground, setShowHeaderBackground,
            isMobile, setIsMobile,
            isWidthHideFilter, setIsWidthHideFilter,
            showFilter, setShowFilter,
            setShowFilterStatus
        }} >
            <App />
        </SalesContext.Provider>
    );
}






