import "./Insights.scss";
import React from "react";
import Insight from "../Insight/Insight";

const img1 = require("./3.jpeg");
const img2 = require("./2.jpeg");
const img3 = require("./1.jpeg");
const img4 = require("./4.jpg");

const imgList = [
    {
        img: img4,
        url: "https://www.jbs.cam.ac.uk/insight/2021/new-vision-for-cambridge-emba-alumni/",
        tag: "INSIGHT",
        header: "New vision for Cambridge EMBA alumni",
        content: "Cambridge Executive MBA alumni and a Cambridge MBA alumna have come together to form New Vision International Group (NVIG) – a platform offering investment advice to Chinese high net worth individuals (HNWIs)."
    },
    // {
    //     img: img2,
    //     url: "",
    //     tag: "ANNUAL",
    //     header: "Opportunities in a Dynamic Economy",
    //     content: "Opportunities in a Dynamic Economy New Vision Sino- UK Annual Symposium Cambridge Executive MBA alumni and a Cambridge MBA alumna have come together to form New Vision International Group (NVIG) – a platform offering investment advice to Chinese high net worth individuals (HNWIs).",
    // },
    // {
    //     img: img3,
    //     url: "",
    //     tag: "INSIGHT",
    //     header: "lorem ipsum dolor sit amet, consectetur adip",
    //     content: "Cambridge Executive MBA alumni and a Cambridge MBA alumna have come together to form New Vision International Group (NVIG) – a platform offering investment advice to Chinese high net worth individuals (HNWIs)."

    // },
    // {
    //     img: img4,
    //     url: "",
    //     tag: "INSIGHT",
    //     header: "New vision for Cambridge EMBA alumni",
    //     // header: "New vision for Cambridge EMBA alumni",
    //     content: "Cambridge Executive MBA alumni and a Cambridge MBA alumna have come together to form New Vision International Group (NVIG) – a platform offering investment advice to Chinese high net worth individuals (HNWIs)."

    // },
];

export default function Insights() {
    return (
        <div className="insights-container">
            <div className="insights-cover">
                <h3 className="about-subtitle">Featured Insights</h3>
            </div>
            <div className="insights-content">

                <div className="insights-list">
                    {imgList.map((i, index) => {
                        if (index === 0) {
                            return < Insight insightInfo={i} key={index} format="vertical" />;
                        } else {
                            return < Insight insightInfo={i} key={index} format="horizontal" />;
                        }
                    })}
                    {/* <Insight />
                    <Insight />
                    <Insight />
                    <Insight /> */}
                </div>
            </div>
        </div>
    );
}