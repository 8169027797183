// Libraries
import "./Sale.scss";
import "./SaleMobile.css";
import "./print.css";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// React components
import SVGView from "./SVG/SVGView";
import GridView from "./Grid/GridView";
import { FilterView } from "./Filter/FilterView";
import { ViewControlView } from "./ViewControl/ViewControlView";

// React context
import { SalesContext } from "../../context/SalesContext";

// helper
import { selectAccessToken } from "../../util/accessTokenSlice";

// Redux slices
import {
  clearFilter,
  selectFilterAll,
  updatePrice,
} from "../../features/filters/filtersSlice";
import {
  selectFavourites,
  asyncFetchFavDevelopments,
  selectFavDevelopments,
} from "../../features/favourites/favouritesSlice";
import {
  selectAllDevelopments,
  asyncFetchDevelopments,
} from "../../features/developments/developmentsSlice";
import { SpinnerCircles } from "../../util/Spinners/Spinners";

const stationData = require("../../data/stationDataFinal.json");

const zoneValue = {
  "zone-one": 1,
  "zone-two": 2,
  "zone-three": 3,
  "zone-four": 4,
  "zone-five": 5,
  "zone-six": 6,
};
const bedrooms = [
  "studio",
  "one-bed",
  "two-bed",
  "three-bed",
  "four-bed",
  "five-bed",
];
const completionValue = {
  completed: "Completed",
  yr2024: 2024,
  yr2025: 2025,
  yr2026: 2026,
  yr2027: 2027,
};
var devInfo = {};
const stationInfo = {};

// ===== helper: create stationInfo{} ===== //
stationData.map((x) => {
  stationInfo[x["Unique-id"]] = {
    transform: x["Matrix"],
    ["station-name"]: x["Name"],
  };
});
// console.log("stationInfo is:");
// console.log(stationInfo);

export const Map = () => {
  const mapRef = useRef(null);
  const gridRef = useRef(null);
  const saleContainerRef = useRef(null);
  const [filterMaxHeight, setFilterMaxHeight] = useState(null);

  const [stopFilterFixed, setStopFilterFixed] = useState(false);
  const footerLineRef = useRef();

  // React context
  // const { dataDevFavAll, setDataDevFavAll } = useContext(SalesContext);
  const { dataDevFav, setDataDevFav } = useContext(SalesContext);
  const { refresh } = useContext(SalesContext);
  const loading = useSelector((state) => state.developments.status);
  // local states
  const [dataMap, setDataMap] = useState({});

  // const [range, setRange] = useState([0, 100]);
  const [grid, setGrid] = useState(true);
  const [sort, setSort] = useState("name");
  const [labels, setLabels] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [showFav, setShowFav] = useState(false);
  const [hideBehindFilter, setHideBehindFilter] = useState(false);

  const [minMax, setMinMax] = useState([0, 100]);

  const [selectHouses, setSelectHouses] = useState(false);
  const { isSmallScreen, lang, loadLang } = useContext(SalesContext);
  const language = new URLSearchParams(window.location.search).get("language");

  useEffect(() => {
    if (language) {
      if (language === "chinese") {
        loadLang("chinese");
      } else if (language === "english") {
        loadLang("english");
      }
    }
  }, [language]);

  useEffect(() => {
    // console.log("fwea");
    // console.log(lang);
    let l;
    l = language || lang;
    console.log(l);

    const elementsToHide =
      l === "english"
        ? document.querySelectorAll("[language=zh]")
        : document.querySelectorAll("[language=en]");
    const elementsToShow =
      l === "english"
        ? document.querySelectorAll("[language=en]")
        : document.querySelectorAll("[language=zh]");
    // document.querySelectorAll('[language=en]').forEach(i => i.classList.add('hideLanguage'));
    // document.querySelectorAll('[language=zh]').forEach(i => i.classList.remove('hideLanguage'));

    elementsToHide.forEach((element) => {
      element.classList.add("hideLanguage");
      element.classList.remove("showLanguage");

      // element.style.display = 'none';
    });
    elementsToShow.forEach((element) => {
      element.classList.remove("hideLanguage");
      element.classList.add("showLanguage");
      // element.style.display = 'block';
    });
  }, [lang, language]);

  // Redux states
  const dispatch = useDispatch();
  const allDevelopments = useSelector(selectAllDevelopments); // use as default
  const allFavourites = useSelector(selectFavourites);
  const allFavDevelopments = useSelector(selectFavDevelopments); // use as default
  const filter = useSelector(selectFilterAll);
  const accessToken = useSelector(selectAccessToken);

  const developmentStatus = useSelector((state) => state.developments.status);
  const favouriteStatus = useSelector(
    (state) => state.favourites.status.allFav
  );
  const favDevelopmentStatus = useSelector(
    (state) => state.favourites.status.allFavDev
  );

  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  // ==================== BUTTON CLICKED, FILTER DATA =========== //
  const updateData = async () => {
    // console.log("in update data");
    let data = showFav ? allFavDevelopments : allDevelopments;
    let zoneData = filterZone(data);
    let postcodeData = filter.postcode ? filterPostcode(zoneData) : zoneData;
    let priceData =
      filter["price"][0] !== filterPrice[0] ||
      filter["price"][1] !== filterPrice[1]
        ? filterPrice(postcodeData)
        : postcodeData;
    let boroughData = filter.borough ? filterBorough(priceData) : priceData;
    let bedroomsData = filterBedrooms(boroughData);
    let completionData = filterCompletion(bedroomsData);
    let developerData = filterDeveloper(completionData);
    let finalData = filterHouses(developerData);

    renderWithSort(sort, [...finalData]);
    renderSVG(finalData);

    // Helper functions for each filter
    function filterZone(data) {
      // console.log("in filter zone");
      var newData = [];
      const keyArr = Object.keys(filter.zone);
      var inputKey = [];
      for (var i = 0; i < keyArr.length; i++) {
        if (filter.zone[keyArr[i]] !== false) {
          inputKey.push(keyArr[i]);
        }
      }
      if (inputKey.length === 0) {
        inputKey = Object.keys(zoneValue);
      }

      for (var i = 0; i < data.length; i++) {
        if (data[i]["zone-main"] || data[i]["zone-other"]) {
          for (var j = 0; j < inputKey.length; j++) {
            if (inputKey[j] === "zone-six") {
              if (data[i]["zone-main"] >= 6 || data[i]["zone-other"] >= 6) {
                newData.push(data[i]);
                break;
              }
            } else {
              if (
                data[i]["zone-main"] === zoneValue[inputKey[j]] ||
                data[i]["zone-other"] === zoneValue[inputKey[j]]
              ) {
                newData.push(data[i]);
                break;
              }
            }
          }
        }
      }
      return newData;
    }

    function filterPostcode(data) {
      var newData = [];
      var temp = filter.postcode.toUpperCase().trim();
      var input;
      if (temp.includes(" ")) {
        input = temp.split(" ");
      } else {
        input = temp;
      }

      for (var i = 0; i < data.length; i++) {
        var answer = data[i]["postcode"].trim().split(" ");
        if (answer.length === 2) {
          if (Array.isArray(input) && input.length === 2) {
            if (input[0] === answer[0]) {
              newData.push(data[i]);
            }
          } else if (!Array.isArray(input) && typeof input === "string") {
            if (input === answer[0]) {
              newData.push(data[i]);
            }
          }
        }
      }
      return newData;
    }

    function filterPrice(data) {
      var newData = [];
      const input = filter["price"];
      const keyArr = Object.keys(filter.bedrooms);
      var inputKey = [];
      for (var i = 0; i < keyArr.length; i++) {
        if (filter.bedrooms[keyArr[i]] !== false) {
          inputKey.push(keyArr[i]);
        }
      }

      if (inputKey.length === 0) {
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < bedrooms.length; j++) {
            if (
              data[i][bedrooms[j]] &&
              data[i][bedrooms[j]] !== 1 &&
              data[i][bedrooms[j]] >= input[0] &&
              data[i][bedrooms[j]] <= input[1]
            ) {
              newData.push(data[i]);
              break;
            }
          }
        }
      } else {
        for (var i = 0; i < data.length; i++) {
          for (var j = 0; j < inputKey.length; j++) {
            if (
              data[i][inputKey[j]] &&
              data[i][bedrooms[j]] !== 1 &&
              data[i][inputKey[j]] >= input[0] &&
              data[i][inputKey[j]] <= input[1]
            ) {
              newData.push(data[i]);
              break;
            }
          }
        }
      }
      return newData;
    }

    function filterBorough(data) {
      var newData = [];
      const input = filter.borough.trim();
      for (var i = 0; i < data.length; i++) {
        if (input === data[i]["borough"].toUpperCase()) {
          newData.push(data[i]);
        } else if (data[i]["borough"].toUpperCase().includes(input)) {
          newData.push(data[i]);
        }
      }
      return newData;
    }

    function filterBedrooms(data) {
      var newData = [];
      const keyArr = Object.keys(filter.bedrooms);
      var inputKey = [];
      for (var i = 0; i < keyArr.length; i++) {
        if (filter.bedrooms[keyArr[i]] !== false) {
          inputKey.push(keyArr[i]);
        }
      }
      if (inputKey.length === 0) {
        inputKey = bedrooms;
      }

      for (var i = 0; i < data.length; i++) {
        if (Array.isArray(inputKey)) {
          for (var j = 0; j < inputKey.length; j++) {
            if (data[i][inputKey[j]]) {
              newData.push(data[i]);
              break;
            }
          }
        } else {
          if (data[i][inputKey]) {
            newData.push(data[i]);
          }
        }
      }

      return newData;
    }

    function filterHouses(data) {
      if (!selectHouses) return data;

      var newData = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].house && data[i].house) {
          newData.push(data[i]);
        }
      }
      return newData;
    }

    function filterDeveloper(data) {
      var newData = [];
      const keyArr = Object.keys(filter.developer);
      const otherDeveloper = filter["other-developer"];
      var inputKey = [];
      for (var i = 0; i < keyArr.length; i++) {
        if (filter.developer[keyArr[i]] !== false) {
          inputKey.push(keyArr[i]);
        }
      }
      if (otherDeveloper !== "") {
        inputKey.push(otherDeveloper);
      }

      for (var i = 0; i < data.length; i++) {
        // if (data[i]["developer"]) {
        if (inputKey.length === 0) {
          newData.push(data[i]);
        } else if (Array.isArray(inputKey)) {
          for (var j = 0; j < inputKey.length; j++) {
            if (
              data[i]["developer"]
                .toUpperCase()
                .includes(inputKey[j].toUpperCase())
            ) {
              newData.push(data[i]);
              break;
            }
          }
        } else {
          if (data[i][inputKey]) {
            newData.push(data[i]);
          }
        }
        // }
      }
      return newData;
    }

    function filterCompletion(data) {
      var newData = [];
      const keyArr = Object.keys(filter.completion);
      var inputKey = [];
      for (var i = 0; i < keyArr.length; i++) {
        if (filter.completion[keyArr[i]] !== false) {
          inputKey.push(keyArr[i]);
        }
      }
      if (inputKey.length === 0) {
        inputKey = Object.keys(completionValue);
      }

      for (var i = 0; i < data.length; i++) {
        if (data[i]["completion-array"]) {
          const temp = data[i]["completion-array"].toString();

          for (var j = 0; j < inputKey.length; j++) {
            if (
              inputKey[j] === "yr2025" &&
              (temp.includes(2025) ||
                temp.includes(2026) ||
                temp.includes(2027) ||
                temp.includes(2028) ||
                temp.includes(2029) ||
                temp.includes(2030))
            ) {
              newData.push(data[i]);
              break;
            } else {
              if (temp.includes(completionValue[inputKey[j]])) {
                newData.push(data[i]);
                break;
              }
            }
          }
        }
      }

      return newData;
    }
  };

  // ==================== SEARCH BY NAME  ===================== //
  const searchByName = (e) => {
    setSearchName(e.target.value);
    dispatch(clearFilter());
    const inputData = showFav ? allFavDevelopments : allDevelopments;
    const finalData = inputData.filter((item) => {
      return item["name"].toUpperCase().includes(e.target.value.toUpperCase());
    });
    setPriceRange(finalData);
    renderWithSort(sort, finalData);
    renderSVG(finalData);
  };

  // ==================== RENDER DATA ===================== //
  const renderWithSort = (value, data) => {
    setSort(value);
    switch (value) {
      case "highest-price":
        setDataDevFav(
          [...data]
            .filter((item) => item["highest-price"] !== 1)
            .sort((a, b) => b["highest-price"] - a["highest-price"])
        );
        break;
      case "lowest-price":
        setDataDevFav(
          [...data]
            .filter((item) => item["lowest-price"] !== 1)
            .sort((a, b) => a["lowest-price"] - b["lowest-price"])
        );
        break;
      case "zone":
        setDataDevFav(
          [...data].sort((a, b) => a["zone-main"] - b["zone-main"])
        );
        break;
      case "name":
        setDataDevFav(
          [...data].sort((a, b) => {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        );
        break;
      default:
        console.log(`error`);
    }
  };

  const loadSVGData = (data) => {
    // ===== helper functions for loadSVGData ===== //
    const adjustMatrix = (dev, value1, value2) => {
      // dev: devInfo[key]
      const arr = dev.transform.replace(")", "").split(" ");
      arr[4] = (
        Number(dev.transform.replace(")", "").split(" ")[4]) + value1
      ).toString();
      arr[5] =
        (
          Number(dev.transform.replace(")", "").split(" ")[5]) + value2
        ).toString() + ")";

      return arr.join(" ");
    };

    const adjustDevelopmentsTransform = (dev, value) => {
      const finalArr = [];
      dev.developments.map((x) => {
        const arr = x; // x: ["10 park drive", "idr930na"]
        arr.push(value);
        value += 12; // TODO: 如果只是英文，用12，中英文用24
        // value += 24; // TODO: 如果只是英文，用12，中英文用24
        finalArr.push(arr);
      });
      return finalArr;
      // multiple: [["10 parkdrive", "id3ijtog", 11],["king's cross", "id3geagtog", 17]]
      // single: [["10 parkdrive", "id3ijtog", 11]]
    };

    // FILTER OUT THE ONES DOESN'T PRESENT ON MAP //
    const inMapData = data.filter((i) => {
      return i["in-map"];
    });

    // CREATE devInfo{}, add it in from inMapData //
    inMapData.map((x) => {
      if (!devInfo[x["unique-station-id"]]) {
        // devInfo里面没有这个staton的时候
        devInfo[x["unique-station-id"]] = {
          "station-name": x.station,
          "unique-station-id": x["unique-station-id"],
          transform: stationInfo[x["unique-station-id"]]
            ? stationInfo[x["unique-station-id"]]["transform"]
            : "",
          developments: [
            [
              x.name,
              x["name-chinese"],
              x["unique-id"],
              x["wechatlink"],
              x["house"] ? true : false,
            ],
          ],
        };
      } else {
        // devInfo里有这个station
        devInfo[x["unique-station-id"]].developments.push([
          x.name,
          x["name-chinese"],
          x["unique-id"],
          x["wechatlink"],
          x["house"] ? true : false,
        ]);
      }
    });

    // ===== make adjustments ===== //
    Object.keys(devInfo).map((key) => {
      // devInfo[key].height = ((devInfo[key].developments.length + 1) * 13); //TODO: 只是英文，用13，中英文用20； 7的倍数
      devInfo[key].height = (devInfo[key].developments.length + 1) * 21; //TODO: 只是英文，用13，中英文用20； 7的倍数
      const devWidth = Math.max(
        ...devInfo[key].developments.map((i) => i[0].length)
      );
      const stationWidth = devInfo[key]["station-name"].length;
      devInfo[key].width =
        (devWidth >= stationWidth ? devWidth : stationWidth) * 6.2;

      // minor adjustment, most likely no need to change
      devInfo[key].transform = devInfo[key].transform
        ? adjustMatrix(devInfo[key], 0, 0)
        : "matrix(1 0 0 1 0 0)";

      // adjust developments name distance, move it away from station name
      devInfo[key].developments = adjustDevelopmentsTransform(devInfo[key], 12);
    });

    const svgAdjustmentAndCreation = {
      // newly created
      brentford: [400, 1120], // brentford
      kingston: [485, 1340], // kingston
      batterseapark: [870, 1210], // battersea park
      kidbrooke: [2160, 1332], // kidbrooke
      staines: [50, 1380], // staines
      hornsey: [1480, 338], // hornsey
      wandsworthtown: [820, 1288.5],

      kewbridge: [400, 1075],
      raynespark: [700, 1550],
      northdulwich: [1380, 1432],
      putney: [665, 1249],
      teddington: [350, 1300],
      watfordnorth: [478, 105],
      ashvale: [116, 1380],
      guildford: [201, 1380],
      earlsfield: [660, 1304],
      blackheath: [1978, 1308],
      hatfield: [1511, 60],
      ascot: [50, 1330],
    };
    Object.keys(svgAdjustmentAndCreation).map((key) => {
      if (devInfo[key]) {
        devInfo[key].transform = adjustMatrix(
          devInfo[key],
          svgAdjustmentAndCreation[key][0],
          svgAdjustmentAndCreation[key][1]
        ); // hanwell
      }
    });

    setDataMap(devInfo);
  };

  const renderSVG = (data) => {
    devInfo = {};
    loadSVGData(data);
  };

  const setPriceRange = (data) => {
    const min = Math.min(
      ...data
        .filter((item) => item["lowest-price"] > 1)
        .map((item) => item["lowest-price"])
    );
    const max = Math.max(
      ...data
        .filter((item) => item["highest-price"] > 1)
        .map((item) => item["highest-price"])
    );
    dispatch(updatePrice([min, max]));
    // min max not influenced by 1,000,000,000 cause it uses "highest-price column"
    setMinMax([min, max]);
  };

  const reloadData = (sort, data) => {
    renderWithSort(sort, data);
    setPriceRange(data);
    renderSVG(data);
  };

  /**
   * show filter, and hide the ones behind
   */
  const mobileShowFilter = () => {
    // setHideBehindFilter(!showFilter);
    // setShowFilter(!showFilter);
  };

  // FETCH AND LOAD ALL FAVOURITES
  useEffect(() => {
    if (showFav && favDevelopmentStatus === "idle") {
      dispatch(asyncFetchFavDevelopments());
    } else if (!showFav && developmentStatus == "idle") {
      dispatch(asyncFetchDevelopments());
    }
    if (showFav && favDevelopmentStatus === "succeeded") {
      // console.log(allFavDevelopments);
      reloadData(sort, allFavDevelopments);
    } else if (!showFav && developmentStatus === "succeeded") {
      // console.log("load");
      reloadData(sort, allDevelopments);
    }
  }, [dispatch, showFav, developmentStatus, favDevelopmentStatus, refresh]);

  useEffect(() => {
    loadLang(lang);
  });

  useEffect(() => {
    // if (!grid) {
    //   setStopFilterFixed(false);
    // }
    const handleScroll = () => {
      const element = footerLineRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const isElementVisible = rect.top < window.innerHeight;
        setStopFilterFixed(isElementVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [grid]);

  // useEffect(() => {
  //   let resizeTimer;

  //   const handleResize = () => {
  //     clearTimeout(resizeTimer);
  //     resizeTimer = setTimeout(() => {
  //       const height = saleContainerRef.current.clientHeight;
  //       console.log(height);
  //       if (height < window.innerHeight) {
  //         setFilterMaxHeight(height);
  //       }
  //     }, 300); // Adjust the delay time as needed
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     clearTimeout(resizeTimer);
  //   };
  // }, [window]);

  // useEffect(() => {
  //   if (saleContainerRef.current) {
  //     console.log(saleContainerRef.current);
  //     const height = saleContainerRef.current.clientHeight;
  //     console.log(height);
  //     if (height < window.innerHeight) {
  //       setFilterMaxHeight(height);
  //     }
  //   }

  // }, [grid, saleContainerRef]);

  // useEffect(() => {
  //   console.log(footerLineRef);
  //   const observerOptions = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 1.0
  //   };

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         setStopFilterFixed(entry.isIntersecting);
  //         observer.disconnect();
  //       }
  //     });
  //   }, observerOptions);

  //   if (footerLineRef.current) {
  //     observer.observe(footerLineRef.current);
  //   }
  //   return () => {
  //     if (footerLineRef.current) {
  //       observer.unobserve(footerLineRef.current);
  //     }
  //   };
  // }, [footerLineRef]);
  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  if (loading === "loading") {
    return (
      <div style={{ marginTop: "60px" }}>
        <SpinnerCircles />
      </div>
    );
  }
  return (
    <div
      className={grid ? "sale-container show-grid" : "sale-container"}
      ref={saleContainerRef}
    >
      <div className={accessToken ? "MainBody" : "MainBody not-logged-in"}>
        {/* <div className={getAccessToken() ? "MainBody" : "MainBody not-logged-in"}> */}
        <ViewControlView
          count={dataDevFav.length}
          sort={sort}
          onChange={(e) => {
            renderWithSort(e.target.value, dataDevFav);
          }}
          setGrid={setGrid}
          grid={grid}
          searchByName={searchByName}
          searchName={searchName}
          showFav={showFav}
          setShowFav={setShowFav}
          // resetFilter={resetFilter}
          renderWithSort={renderWithSort}
          renderSVG={renderSVG}
          mobileShowFilter={mobileShowFilter}
          setPriceRange={setPriceRange}
          updateData={updateData}
          minMax={minMax}
          stopFilterFixed={stopFilterFixed}
          filterMaxHeight={filterMaxHeight}
        />
        {/* <SVGView print={true} devInfo={dataMap} showLabels={labels} /> */}

        <div className={"grid-svg-container"} id="map-to-print">
          {/* <div className={showFilter ? "grid-svg-container hide" : "grid-svg-container"} id="map-to-print"> */}

          {
            grid ? (
              <GridView
                ref={gridRef}
                data={dataDevFav}
                onClick={openLink}
                showFav={showFav}
                renderWithSort={renderWithSort}
                sort={sort}
              />
            ) : (
              <>
                <SVGView
                  print={false}
                  devInfo={dataMap}
                  showLabels={labels}
                  setLabels={setLabels}
                />
                {/* <SVGView print={true} devInfo={dataMap} showLabels={labels} /> */}
              </>
            )
            // : <SVGView print={false} devInfo={dataMap} showLabels={labels} />
          }
        </div>
        <FilterView
          // {accessToken && <FilterView
          setPriceRange={setPriceRange}
          updateData={updateData}
          renderWithSort={renderWithSort}
          renderSVG={renderSVG}
          sort={sort}
          showFav={showFav}
          minMax={minMax}
          mobileShowFilter={mobileShowFilter}
          stopFilterFixed={stopFilterFixed}
          filterMaxHeight={filterMaxHeight}
          grid={grid}
          selectHouses={selectHouses}
          setSelectHouses={setSelectHouses}
        />
      </div>
      <div className="rent-white-transparent-divider" ref={footerLineRef}></div>
    </div>
  );
};
