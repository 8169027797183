import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./SVGView.css";
import "./SVGViewMobile.css";
// import MapSVG from "./MapSVG/MapSVGNoIndicator";
import MapSVG from "./MapSVG/MapSVG";
import DevelopmentOnMap from "./DevelopmentOnMap/DevelopmentOnMap";
import { selectAccessToken } from "../../../util/accessTokenSlice";
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import { updateScaleLevel, selectMapzoomScaleLevel } from "../../../features/mapzoom/mapzoomSlice";
import { SalesContext } from "../../../context/SalesContext";

const plusSign = require("../../../assets/media/icons/plus-sign-squarecorner-white.png");
const minusSign = require("../../../assets/media/icons/minus-sign-squarecorner-white.png");
const restoreSign = require("../../../assets/media/icons/refresh-white5.png");
const hideSign = require("../../../assets/media/icons/hide-white3.png");
const unhideSign = require("../../../assets/media/icons/unhide-white.png");


export default function SVGView(props) {

    const accessToken = useSelector(selectAccessToken);
    const transformComponentRef = useRef(null);
    const { isMobile, isWidthHideFilter } = useContext(SalesContext);
    const [localIsMobile, setLocalIsMobile] = useState(isMobile);

    useEffect(() => {
        setLocalIsMobile(isMobile);
    }, [isMobile]);

    const hideLabels = (e) => {
        props.setLabels(props.showLabels ? false : true);
    };

    const Control = ({ zoomIn, zoomOut, resetTransform }) => (
        <div className="svgview-zoom-button-container">
            <div>
                <button
                    className="svgview-zoom-button svgview-zoom-button-restore"
                    onClick={() => resetTransform()} ><img src={restoreSign} /></button>
            </div>
            <div>
                <button className="svgview-zoom-button svgview-zoom-button-in" onClick={() => zoomIn()}><img src={plusSign} /></button>
                <button className="svgview-zoom-button svgview-zoom-button-out" onClick={() => zoomOut()}><img src={minusSign} /></button>
                {/* <button className={disableZoomInButton ? "disable svgview-zoom-button svgview-zoom-button-in" : "svgview-zoom-button svgview-zoom-button-in"} onClick={(e) => { zoomTo(1, "click", e); }}><img src={plusSign} /></button>
                <button className={disableZoomOutButton ? "disable svgview-zoom-button svgview-zoom-button-out" : "svgview-zoom-button svgview-zoom-button-out"} onClick={(e) => { zoomTo(-1, "click", e); }}><img src={minusSign} /></button> */}
            </div>
            <div>
                <button className="svgview-zoom-button svgview-zoom-button-hide" onClick={hideLabels}><img src={props.showLabels ? hideSign : unhideSign} /></button>
            </div>
        </div >
    );

    return (
        <div className="svgview-container" id={props.print ? "print-svg-container" : "svg-container"}>
            <TransformWrapper
                key={isMobile ? 'mobile' : 'desktop'}
                initialScale={localIsMobile ? (3) : 1}
                initialPositionX={localIsMobile ? -300 : 0}
                initialPositionY={localIsMobile ? -500 : 0}
                doubleClick={true}
                ref={transformComponentRef}
            >{(utils) => (
                <React.Fragment>
                    <Control {...utils} />
                    <TransformComponent>
                        <div className="svg-zoom">
                            <MapSVG id="tube-svg" print={props.print} />
                            {props.showLabels && (
                                <svg
                                    id="dev-svg"
                                    viewBox="0 0 2939.38 1794.33"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width={isWidthHideFilter ? (isMobile ? "auto" : "100vw") : (props.print ? "110vw" : "calc(100vw - 350px)")}
                                    height={isWidthHideFilter ? (isMobile ? "calc(100vh - 207.13px)" : "auto") : ("auto")}
                                    enableBackground="new 0 0 1247.244 907.087"
                                >
                                    {/* <svg id="dev-svg" viewBox="0 0 2939.38 1794.33" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={props.print ? "110vw" : (accessToken ? "calc(100vw - 350px)" : "100vw")} height="auto" enableBackground="new 0 0 1247.244 907.087"> */}
                                    {Object.keys(props.devInfo).map((key) => {
                                        return <DevelopmentOnMap obj={props.devInfo[key]} key={key} />;
                                    })}
                                </svg>
                            )}
                        </div>
                    </TransformComponent>
                </React.Fragment>
            )}
                {/* <div className="svgview-indicator">
                    <div className="svgview-keys-to-symbol">
                        <div>
                            <img />
                            <p>
                                <span>School</span>
                                <span>学校</span>
                            </p>
                        </div>
                        <div>
                            <img />
                            <p>
                                <span>Park</span>
                                <span>公园</span>
                            </p>
                        </div>
                        <div>
                            <img />
                            <p>
                                <span>Park</span>
                                <span>公园</span>
                            </p>
                        </div>
                    </div>
                    <div className="svgview-keys-to-lines">
                        <h3>Key to lines</h3>
                        <p id="svgmap-line-bakerloo">Bakerloo</p>
                        <p id="svgmap-line-central">Central</p>
                        <p id="svgmap-line-circle">Circle</p>
                        <p id="svgmap-line-district">District</p>
                        <p id="svgmap-line-hammersmithcity">Hammersmith & City</p>
                        <p id="svgmap-line-jubilee">Jubilee</p>
                        <p id="svgmap-line-metropolitan">Metropolitan</p>
                        <p id="svgmap-line-northern">Northern</p>
                        <p id="svgmap-line-piccadilly">Piccadilly</p>
                        <p id="svgmap-line-victoria">Victoria</p>
                        <p id="svgmap-line-waterloocity">Waterloo & City</p>
                        <p id="svgmap-line-dlr">DLR</p>
                        <p id="svgmap-line-elizabeth">Elizabeth line</p>
                        <p id="svgmap-line-overground">London Overground</p>
                        <p id="svgmap-line-trams">London Trams</p>
                        <p id="svgmap-line-cabel">London Cabel Car</p>
                        <p id="svgmap-line-thameslink">Thameslink</p>
                        <p id="svgmap-line-district">District</p>
                    </div>
                </div> */}


            </TransformWrapper>
        </div >
    );
};